import { useState, useCallback, useEffect } from 'react';

const useSelectContactInfo = () => {
  const [contactInfo, setContactInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: ''
  });

  const [appointmentConfirmed, setAppointmentConfirmed] = useState(false);

  // Invoked after user has filled in all contact information
  // and clicks CTA to update contactInfo state with values from passed in argument.
  // Assuming there is no appointment confirmation page, artificially set the appointmentConfirmed state to true.
  // This will be modified in the final implementation to show either handle a confirmation page and a progress page.
  // useCallback memoizes this function so that as a prop it will not change unless contactInfo changes.
  // Note that we do not directly mutate the state since that would not cause the necessary rerender due to referential equality.
  const handleSelectedContactInfo = useCallback(
    (selectedContactInfo) => {
      setContactInfo(() => ({
        ...contactInfo,
        ...selectedContactInfo
      }));
      setAppointmentConfirmed(true);
    }, [contactInfo]
  );



  return ({ contactInfo, appointmentConfirmed, handleSelectedContactInfo });
};

export default useSelectContactInfo;
