import { useRef } from 'react';
import { COMPONENT_STATE } from '../constants/componentState';

const useStateManagement = (handleResetSelectedService, handleResetSelectedDateTime) => {
  // This array of states represents the sequence of book appointment child components.
  // inactive - component displays greyed out version.
  // active - component allow user input to gather its information.
  // completed - user information collected. Displays selected choices and edit button.
  //
  const stateSequencer = useRef([
    {
      name: 'select_vehicle',
      state: COMPONENT_STATE.active
    },
    {
      name: 'select_service',
      state: COMPONENT_STATE.inactive,
      dependency: 'select_vehicle',
      resetHandler: handleResetSelectedService
    },
    {
      name: 'select_datetime',
      state: COMPONENT_STATE.inactive,
      dependency: 'select_service',
      resetHandler: handleResetSelectedDateTime
    },
    {
      name: 'select_contact',
      state: COMPONENT_STATE.inactive
    },
    {
      name: 'setting_up_appointment',
      state: COMPONENT_STATE.inactive
    },
    {
      name: 'appointment_confirmed',
      state: COMPONENT_STATE.inactive
    },
  ]);

  // Returns the current child component index and its state given the passed in state name.
  // A new current component state object is created from the found state to be passed in as a prop to the child component.
  // This allows the child component to rerender since it's assumed the state will change.
  const getComponentState = (componentName) => {
    const foundIndex = stateSequencer.current.findIndex((component) => {
      return component.name === componentName
    });
    const foundState = stateSequencer.current[foundIndex];
    const currentComponentState = { name: foundState.name, state: foundState.state }
    return { foundIndex, foundState, currentComponentState };
  };

  // Given the current component state index, return the next component state in the sequence.
  // Wrap when we get to the last component. Wrap when we get to the end.
  const getNextComponentState = (currentStateIndex) => {
    const nextStateIndex = currentStateIndex === stateSequencer.current.length - 1 ?  0 : currentStateIndex + 1;
    return stateSequencer.current[nextStateIndex];
  };

    // Whenever a child component goes into the completed state, check if there are any dependent downstream child components.
  // If so, then reset previously selected data for that dependent component and change its state from completed to inactive.
  // Example:
  // Vehicle and service components are in completed state and then user clicks vehicle component Edit button and selects a new vehicle.
  // Since select service component depends on the selected vehicle, we must reset the selected service.
  const resetDownstreamData = (currentStateName) => {
    const currentStateIndex = stateSequencer.current.findIndex(component => { return component.name === currentStateName });
    for (let index = currentStateIndex+1; index < stateSequencer.current.length; index++) {
      const component = stateSequencer.current[index];
      if (component?.dependency && typeof(component?.resetHandler) === 'function') {
        component.resetHandler();
        component.state = COMPONENT_STATE.inactive;
      };
    };
  };
  return ({ stateSequencer, getComponentState, getNextComponentState, resetDownstreamData });
}

export default useStateManagement;
