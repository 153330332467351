import { gql } from '@apollo/client';

export default gql`
  query yearList($makeId: String, $modelId: String) {
    list: years(makeId: $makeId, modelId: $modelId) {
      yearId
      text
      value
    }
  }
`;
