import React from 'react';
import { Button } from '@argo/ui-elements';
import { breakpoints, size } from '@argo/principles';
import styled from '@emotion/styled';
import ddpDataAnalytics from '../../utils/ddpDataAnalytics';
import ddpPixallEvents from '../../utils/pixAll/ddpPixAllEvents';

const StyledButton = styled(Button)`
  font-family: "'Montserrat SemiBold Regular',sans-serif";
  font-size: ${size.sm}px;
  font-weight: bold;
  width: 422px;
  margin-bottom: 0;
  padding-bottom: 0;
  @media (max-width: ${breakpoints.xl}px) {
    width: 360px;
  }
  @media (max-width: ${breakpoints.lg}px) {
    width: 100%;
  }
`;
const PhoneCallButton = ({ phoneNumber, ddpDataLayer, ddpPixallDataLayer, displayedDealer }) => {
  const { provider, svocId, type } = displayedDealer;
  return (
    <StyledButton
    data-cy="phoneCallButton"
      buttonType="tertiary"
      horizontalPadding="0"
      data-testid={`tel_${phoneNumber.replace(/\D/g, '')}_lnk`}
      href={`tel:${phoneNumber}`}
      text={`Call for Service - ${phoneNumber}`}
      onClick = {(event) => {
        ddpDataAnalytics.fireDdpClick(ddpDataLayer, ddpDataAnalytics.clicks.phoneLinkClick(svocId, provider, type));
        ddpPixallEvents.fireDdpPixallClick(ddpPixallDataLayer, ddpPixallEvents.clicks.phoneLinkClickDdp(svocId, provider));
        if (typeof CAIEventBus !== 'undefined') {
          const adobeEvent = {
            reaxlName: 'adobeClickOnlyWebComponent',
            dealerId: svocId,
            event: event,
            cmp: 'dlr_tel'

          }
          CAIEventBus.emit('serviceTab:adobeevent', { eventMessage: 'Dealer Call Number has been clicked', eventData: adobeEvent });
        }
      }}
    ></StyledButton>
  );
};

export default PhoneCallButton;
