import React from 'react';
import { Image } from '@argo/ui-elements';
import styled from '@emotion/styled';
import { breakpoints } from '@argo/principles';

// Max image dimensions are part of the business rules, see US1025484
const StyledImage = styled(Image)`
  width: 100%;
  height: auto;
  display: block;
  object-fit: contain;

  @media (min-width: ${breakpoints.lg + 1}px) {
    max-width: 374px;
    max-height: 281px;
  }

  @media (max-width: ${breakpoints.lg}px) {
    max-height: 400px;
  }
`;

export const PromoImage = ({ imageUrl }) => {
  if (!imageUrl || typeof imageUrl !== 'string') return null;
  return <StyledImage altText="Promotional Image" src={imageUrl} />;
};
