import React from 'react';
import styled from '@emotion/styled';
import { FlexBox } from '@argo/principles';
import { Spinner } from '@argo/ui-elements';

const StyledMessage = styled.p`
  margin-top: 20px;
  text-align: center;
  width: 100%;
`;

const AppointmentSpinner = () => {
  return (
    <div className='applitoolsLayoutOnly'>
      <FlexBox justifyContent="center" flexWrap="wrap">
        <StyledMessage>
          Connecting to appointment scheduler.
          <br />
          Thanks for your patience.
        </StyledMessage>
        <Spinner className='applitoolsLayoutOnly'></Spinner>
      </FlexBox>
    </div>
  );
};

export default AppointmentSpinner;
