/** @jsx jsx */

import { jsx } from '@emotion/react';
import React, { useState, useContext, Fragment, useEffect } from 'react';
// import { Click } from 'constants/analytics';
import { CustomDate, formatTime } from '../../utils/dateTimeUtils';

import { FlexBox, ContentBlock } from '@argo/principles';
import { Tab, Tabs, ToggleRadio } from '@argo/ui-elements';

export const ServiceCalendarTime = ({
  availableAppointmentData: { data },
  calendarSelectedTime,
  setCalendarSelectedTime,
  calendarSelectedDate,
  shortProvider,
  shortSubProvider,
  setDateHasChanged
}) => {
  const { availableAppointments } = data;

  const [morningSlots, setMorningSlots] = useState([]);
  const [afternoonSlots, setAfternoonSlots] = useState([]);
  const [activeTabIndex, setActiveTabIndex] = useState(0); // 0 is morning, 1 is afternoon tab

  useEffect(() => {
    generateTimeSlots(availableAppointments);
  }, [calendarSelectedDate]);

  const onTabSelected = (tabIndex) => {
    setActiveTabIndex(tabIndex);
  };

  const generateTimeSlots = (initialData) => {
    const { availableAppointments, providerInfo } = initialData;
    const morningSlots = [];
    const afternoonSlots = [];

    if (availableAppointments) {
      for (var i = 0; i < availableAppointments.length; i++) {
        const apptXTime = availableAppointments[i].appointmentDateTimeLocal;
        const offsetDate = new CustomDate(apptXTime);
        const apptHour = offsetDate.hours;

        if (apptHour < 12) {
          morningSlots.push(apptXTime);
        } else {
          afternoonSlots.push(apptXTime);
        }
      }
    }
    setMorningSlots(morningSlots);
    setAfternoonSlots(afternoonSlots);
    // handle edge cases where we need active tab to default to 'Afternoon'
    if (morningSlots.length === 0) {
      setActiveTabIndex(1);
    } else if (calendarSelectedTime) {
      if (formatTime(calendarSelectedTime).includes('pm')) {
        setActiveTabIndex(1);
      }
    } else {
      setActiveTabIndex(0);
    }
  };

  const renderTimeSlots = () => {
    const event = 'event102';
    const touch = 'false';
    let visibleTimeSlots = morningSlots;
    if (activeTabIndex === 1) {
      visibleTimeSlots = afternoonSlots;
    }
    const JSX = visibleTimeSlots.map((time, index) => {
      return (
        <div
          key={index}
          data-testid="time_select_btn"
          data-first-touch-only={touch}
        >
          <ToggleRadio
            style={{ marginLeft: '1px' }}
            data-testid="SelectedTime-Radio"
            isShowRadio={false}
            labelConfig={{
              label: formatTime(time),
              textAlign: 'left'
            }}
            onClick={() => {
              setDateHasChanged(false);
              setCalendarSelectedTime(time);
            }}
            radioConfig={{
              disabled: false,
              id: new Date(time),
              name: 'radio-time',
              checked: Number(new Date(calendarSelectedTime)) === Number(new Date(time)),
              padding: '8px'
            }}
          />
        </div>
      );
    });
    return JSX;
  };

  return (
    <Fragment>
      <ContentBlock verticalSpacing="md">
        <div />
      </ContentBlock>
      <Tabs
        isDivider
        isNarrow
        activeTabIndex={activeTabIndex}
        onTabSelected={onTabSelected}
        dynamicTabIndex={activeTabIndex}
      >
        <Tab label="Morning" disabled={morningSlots.length === 0} />
        <Tab label="Afternoon" />
      </Tabs>

      <FlexBox
        alignItems="left"
        default={['0 0 20', '0 0 20', '0 0 20', '0 0 20', '0 0 20']}
        horizontalSpacing="xs"
        justifyContent="flex-start"
        verticalSpacing="xs"
        flexWrap="wrap"
      >
        <FlexBox
          alignItems="left"
          default={['0 0 20', '0 0 20', '0 0 20', '0 0 20', '0 0 20']}
          horizontalSpacing="xs"
          justifyContent="flex-start"
          verticalSpacing="xs"
          flexWrap="wrap"
        >
          {renderTimeSlots()}
        </FlexBox>
      </FlexBox>
    </Fragment>
  );
};

export default ServiceCalendarTime;
