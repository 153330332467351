import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import CrmCalendarTime from './CrmCalendarTime';
import { Calendar } from '@argo/base-patterns';
import { FlexBox, colors, typography } from '@argo/principles';
import { Tab, Tabs } from '@argo/ui-elements';
import {
  formatDate,
  formatTime,
  getFirstApptDate,
  getFirstAvailableApptDateFromNow,
  defaultDaysOutOffset
} from '../../../utils/dateTimeUtils';

const divHeightstyle = {
  height: 320,
  display: 'inline-block'
};

const StyledCalendar = styled(Calendar)`
  max-width: 380px;
  padding: 0;
`;

const StyledCalendarTimeContainer = styled.div`
  max-width: 380px;
`;

const StyledFlexBoxDateAndTime = styled(FlexBox)`
  width: 380px;
  @media (max-width: 440px) {
    width: 100%;
  }
`;

const StyledDateTimeText = styled.div`
  ${typography.types.XSExtra};
  color: ${colors.primary.black};
  cursor: pointer;
`;

const StyledSpan = styled.span`
  font-weight: 400;
`;

const CrmServiceCalendar = ({
  isEntryView,
  shortProvider,
  shortSubProvider,
  calendarSelectedDate,
  calendarSelectedTime,
  setCalendarSelectedDate,
  setCalendarSelectedTime,
  appointmentInfo,
  daysOutOffset = defaultDaysOutOffset
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [dateHasChanged, setDateHasChanged] = useState(false);

  const [firstValidApptDate] = useState(() => {
    return getFirstAvailableApptDateFromNow({daysOutOffset});
  });

  const [nextAppointmentDate, setNextAppointmentDate] = useState(() => {
    return getFirstAvailableApptDateFromNow({daysOutOffset});
  });

  useEffect(() => {
    // if there is appointInfo, we set to states that were stored and set active tab index depending on selected time
    if (appointmentInfo?.startTime && appointmentInfo?.startDate) {
      setCalendarSelectedTime(appointmentInfo?.startTime);
      const formattedTime = new Date(calendarSelectedTime);
      setActiveTabIndex(formattedTime.getHours() < 12 ? 0 : 1);
    } else {
      setCalendarSelectedDate(firstValidApptDate);
    }
  }, []);

  const [activateTimeSelection, setActivateTimeSelection] = useState(true);

  const onTabSelected = (tabIndex) => {
    setActiveTabIndex(tabIndex);
  };

  const selectCalendarDate = (selectedDay) => {
    // Based on selected date, get next valid appointment date
    const selectedDate = new Date(selectedDay.year, selectedDay.month, selectedDay.date);
    const firstAppointmentDate = getFirstApptDate({
      selectedDate,
      daysOutOffset,
      startTime: calendarSelectedTime
    });
    if (firstAppointmentDate.getDate() !== calendarSelectedDate.getDate()) {
      setDateHasChanged(true);
    }
    setNextAppointmentDate(firstAppointmentDate);
    setCalendarSelectedDate(firstAppointmentDate);
    setActivateTimeSelection(true);
    setActiveTabIndex(0);
  };

  const getNormalizedTime = (selectedTime) => {
    // Convert selected time to format expected by parent component
    // if there is selected date, use selected date for normalized time otherwise use next appointment date
    const formattedCalendarSelectedTime = calendarSelectedDate ? new Date(calendarSelectedDate) : nextAppointmentDate;
    const formattedMonth = (formattedCalendarSelectedTime.getMonth() + 1).toLocaleString('default', {
      minimumIntegerDigits: 2
    });
    const formattedDay = formattedCalendarSelectedTime.getDate().toLocaleString('default', { minimumIntegerDigits: 2 });
    const formattedHour = selectedTime.hour.toLocaleString('default', { minimumIntegerDigits: 2 });
    const formattedMinute = selectedTime.minute.toLocaleString('default', { minimumIntegerDigits: 2 });
    const tzOffsetHours = `${('0' + (formattedCalendarSelectedTime.getTimezoneOffset() / 60).toString()).slice(-2)}:00`;
    const formattedDateTime = `${formattedCalendarSelectedTime.getFullYear()}-${formattedMonth}-${formattedDay}T${formattedHour}:${formattedMinute}-${tzOffsetHours}`;
    return formattedDateTime;
  };

  const selectedCalendarTime = (selectedTime) => {
    setDateHasChanged(false);
    setCalendarSelectedTime(getNormalizedTime(selectedTime));
  };

  return (
    <div>
      <FlexBox
        horizontalSpacing="sm"
        verticalSpacing="sm"
        flexWrap="wrap"
        default={['0 0 50%', '0 0 50%']}
        sm={['0 0 100%', '0 0 100%']}
        md={['0 0 100%', '0 0 100%']}
      >
        <div style={divHeightstyle}>
          <StyledFlexBoxDateAndTime justifyContent="space-between">
            <StyledDateTimeText
              data-testid="date-button"
              onClick={() => {
                setActivateTimeSelection(false);
              }}
            >
              {`Date: `}
              <StyledSpan style={{ fontWeight: 400 }}>
                {calendarSelectedDate ? formatDate(calendarSelectedDate) : ''}
              </StyledSpan>
            </StyledDateTimeText>
            <StyledDateTimeText
              data-testid="time-button"
              onClick={() => {
                setActivateTimeSelection(true);
              }}
            >
              {`Time: `}
              <StyledSpan>
                {!dateHasChanged && calendarSelectedTime ? formatTime(calendarSelectedTime) : 'Select a Time'}
              </StyledSpan>
            </StyledDateTimeText>
          </StyledFlexBoxDateAndTime>
          {activateTimeSelection ? (
            <div>
              <Tabs
                isDivider
                isNarrow
                activeTabIndex={activeTabIndex}
                onTabSelected={onTabSelected}
                dynamicTabIndex={activeTabIndex}
              >
                <Tab label="Morning" />
                <Tab label="Afternoon" />
              </Tabs>
              <StyledCalendarTimeContainer data-testid="service-calendar-time">
                <CrmCalendarTime
                  activeTabIndex={activeTabIndex}
                  getNormalizedTime={getNormalizedTime}
                  selectedCalendarTime={selectedCalendarTime}
                  shortProvider={shortProvider}
                  shortSubProvider={shortSubProvider}
                  calendarSelectedTime={calendarSelectedTime}
                  calendarSelectedDate={calendarSelectedDate}
                />
              </StyledCalendarTimeContainer>
            </div>
          ) : (
            <StyledCalendar
              data-testid="service-calendar"
              data-cy="crm-calendar"
              defaultDate={firstValidApptDate}
              defaultActiveDate={nextAppointmentDate}
              onSelectDate={(selectedDay) => selectCalendarDate(selectedDay)}
              disablePastDates
            />
          )}
        </div>
      </FlexBox>
    </div>
  );
};

export default CrmServiceCalendar;
