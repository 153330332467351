import React from 'react';
import { FlexBox, size, typography, breakpoints } from '@argo/principles';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Heading, Image, Paragraph } from '@argo/ui-elements';

const imageStyles = css`
  display: block;
  width: 95px;
  height: auto;
  align-self: flex-start;
`;

const divContainerStyles = css`
  margin: ${size.md}px 0;
  background-color: #fff;
  padding-left: ${size.md}px;
  padding-right: ${size.md}px;
`;
const StyledParagraph = styled(Paragraph)`
  display: block;
  ${typography.types.body};
  line-height: 14px;
  @media (max-width: ${breakpoints.md}px) {
    ${typography.types.body12};
  }
`;
const StyledDivText = styled.div`
  margin-top: ${size.xs}px;
  padding-bottom: ${size.xl}px;
`;

const StyledImageContainer = styled.div`
  place-self: start;
`;

const StyledSideTextContainer = styled.div`
  place-self: start;
  margin-top: ${size.sm}px;
`;

const StyledText = styled(Paragraph)`
  display: block;
  ${typography.types.body12};
  line-height: 14px;
  @media (max-width: ${breakpoints.md}px) {
    ${typography.types.bodySmall};
  }
`;
const StyledMainHeading = styled(Heading)`
  ${typography.types.MExtra};
  margin-bottom: ${size.none}px;
  padding-top: ${size.xl}px;
  @media (max-width: ${breakpoints.md}px) {
    ${typography.types.SExtra};
  }
`;
const StyledHeading = styled(Heading)`
  ${typography.types.SExtra};
  @media (max-width: ${breakpoints.md}px) {
    ${typography.types.XSExtra};
  }
`;

const DealerHomeServices = () => {
  return (
    <div css={divContainerStyles} data-cy="dealerHomeServices">
      <StyledMainHeading headingName="ContentMainHeadingExtra" headingTag="h2" id="ValetServiceFilterHeader">
        Dealer Home Services
      </StyledMainHeading>
      <FlexBox flexWrap={'nowrap'} alignItems="center" default={['1 1 60%', '1 1 40%']} horizontalSpacing={'md'}>
        <FlexBox flexWrap={'nowrap'} alignItems="center" justifyContent="flex-start">
          <StyledImageContainer>
            <Image
              css={imageStyles}
              src="https://file.kbb.com/kbb/images/ownership/MaintenanceAtDoor.png"
              alt="Maintenance From Your Door"
              width="240"
              height="108"
              isLazy
            />
          </StyledImageContainer>
          <StyledSideTextContainer>
            <StyledHeading headingName="ContentHeadingExtra" headingTag="h4" id="ValetServiceFilterHeader">
              Maintenance From Your Door
            </StyledHeading>
            <StyledParagraph>
              Once you own the car and need service, this dealer will pick it up, do the work and bring your vehicle
              back. Ask your dealer about this service.
            </StyledParagraph>
          </StyledSideTextContainer>
        </FlexBox>
      </FlexBox>

      <StyledDivText>
        <StyledText>
          All Dealer Home Services must comply with state or local ordinances. For important participation details and
          mileage limitations, please ask your dealer.
        </StyledText>
      </StyledDivText>
    </div>
  );
};

export default DealerHomeServices;
