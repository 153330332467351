import React from 'react';
import styled from '@emotion/styled';
import { colors, typography } from '@argo/principles';

const StyledTagline = styled.h3`
  ${typography.defaults.MSemi};
  align-items: center;
  color: ${colors.green.base};
  margin: 0;
  text-align: center;
`;

const StyledDescription = styled.p`
  ${typography.defaults.bodyL}
  margin: 0;
`;

const StyledDisclaimer = styled.p`
  ${typography.defaults.body12}
  line-height: 16px;
  margin: 0;
  &:hover {
    pointer-events: none !important;
    cursor: none;
  }
`;

const componentMap = {
  tagline: StyledTagline,
  description: StyledDescription,
  disclaimer: StyledDisclaimer
};

export const PromoTextElement = ({ text, variant }) => {
  if (!text || typeof text !== 'string') return null;
  const Component = componentMap[variant] || StyledDescription;

  return <Component>{text}</Component>;
};
