import React from 'react';
import { typography, colors, size, GridContainer } from '@argo/principles';
import styled from '@emotion/styled';
import { CheckmarkIcon, Paragraph } from '@argo/ui-elements';

const StyledGridContainer = styled(GridContainer)`
  align-items: center;
  justify-content: space-between;
  align-items: flex-start;
`;

const StyledHeader = styled.h4`
  ${typography.types.SExtra};
  color: ${colors.primary.black};
  text-align: left;
  margin-bottom: 8px;
`;

const StyledDiv = styled.div`
  ${typography.types.body};
  color: ${colors.primary.black};
  display: flex;
  gap: ${size.xs}px;
  align-items: flex-start;
  margin-bottom: ${size.micro}px;
`;

const StyledSpan = styled.span`
  display: inline-flex;
  WebkitBoxAlign: center;
  align-items: center;
  margin-top: ${size.micro}px;
`;

const StyledParagraph = styled(Paragraph)`
  display: flex;
  ${typography.types.body};
  color: ${colors.primary.black};
  align-items:center;
`;

const AmenitiesAndServicesComponent = ({ items, header }) => {
  return (
    <>
      <StyledHeader>{header}</StyledHeader>
      {items.map((item, index) => (
        <StyledDiv key={index}>
          <StyledSpan><CheckmarkIcon color="black" size="16" /></StyledSpan>
          <StyledParagraph>{item}</StyledParagraph>
        </StyledDiv>
      ))}
    </>
  );
};

const ServiceCenterServicesAndAmenities = ({ displayedDealer, breakpointKey = 'md' }) => {
  const { serviceCentersAmenities, services } = displayedDealer;

  if (!serviceCentersAmenities && !services){
    return null;
  }

  return ( 
    <div data-cy="ServiceDepartmentServicesAndAmenities">            
      <StyledGridContainer columnGap="md" gridTemplateColumns={{ [breakpointKey]: "1fr 1fr"}}>        
        {services && (
          <div>
            <AmenitiesAndServicesComponent 
              items={services}
              header='Services Offered' />
          </div> 
        )}
        {serviceCentersAmenities && (
          <div>
            <AmenitiesAndServicesComponent 
              items={serviceCentersAmenities}
              header='Service Amenities' />
          </div> 
        )}
      </StyledGridContainer>  
    </div>    
  )
}; 

export default ServiceCenterServicesAndAmenities;
