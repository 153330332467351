import { gql } from '@apollo/client';

export default gql`
  query makeList($yearId: String) {
    list: makes(yearId: $yearId) {
      text: makeName
      value: makeId
    }
  }
`;
