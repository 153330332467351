import React, { useRef } from 'react';
import { FlexBox, typography } from '@argo/principles';
import { Box, VehicleIcon } from '@argo/ui-elements';
import styled from '@emotion/styled';
import { FormSelectionEdit, VehiclePickerBase } from '@kbbhydra/common-ui';
import { COMPONENT_STATE } from '../constants/componentState';
import  ddpDataAnalytics  from '../utils/ddpDataAnalytics';
import ddpPixAllEvents from '@kbbhydra/service-tab-container/src/utils/pixAll/ddpPixAllEvents';

const StyledBox = styled(Box)`
  min-height: 100%;
`;

const StyledFlexBox = styled(FlexBox)`
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 8px;
`;

const SelectVehicle = ({
  componentState,
  changeStateHandler,
  selectedHandler,
  client,
  makes,
  setSelectedMake,
  ddpDataLayer,
  ddpPixallDataLayer,
  svocId
}) => {
  const selectedVehicleInfo = useRef({
    YearId: '',
    MakeId: '',
    MakeName: '',
    ModelId: '',
    ModelName: '',
    TrimId: '',
    VehicleId: '',
    ChromeStyleIds: '',
    displayText: ''
  });

  const handleVehicleInfoSelected = (vehiclePickerData) => {
    ddpDataAnalytics.fireDdpClick(ddpDataLayer, ddpDataAnalytics.clicks.selectVehicleDdp(vehiclePickerData));  
    ddpPixAllEvents.fireDdpPixallClick(ddpPixallDataLayer, ddpPixAllEvents.clicks.selectVehicleDdp(vehiclePickerData, svocId));

    selectedVehicleInfo.current = vehiclePickerData;
    selectedHandler(selectedVehicleInfo.current);
    setSelectedMake(vehiclePickerData.MakeName);
    selectedVehicleInfo.current.displayText =
      selectedVehicleInfo.current.YearId +
      ' ' +
      selectedVehicleInfo.current.MakeName +
      ' ' +
      selectedVehicleInfo.current.ModelName;
    changeStateHandler({ name: componentState.name, state: COMPONENT_STATE.completed });
  };

  const handleEditVehicle = () => {
    changeStateHandler({ name: componentState.name, state: COMPONENT_STATE.active });
  };

  return (
    <StyledBox backgroundColor="white" response={false} shadow={false} width={'100%'}>
      <FlexBox flowColumn verticalSpacing="sm">
        <FormSelectionEdit
          icon={VehicleIcon}
          iconTestId="vehicleIcon"
          componentState={componentState}
          completedText={selectedVehicleInfo?.current?.displayText}
          defaultText="Your Vehicle"
          handleEdit={handleEditVehicle}
          labelTypography={typography.types.MSemi}
        />
        <VehiclePickerBase
          handleVehicleInfoSelected={(vehiclePickerData) => handleVehicleInfoSelected(vehiclePickerData)}
          componentState={componentState}
          makes={makes}
          client={client}
        />
      </FlexBox>
    </StyledBox>
  );
};

export default SelectVehicle;
