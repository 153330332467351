import React, { useEffect, useState } from 'react';
import { useStepByStepFormUnlocker } from '@argo/utils';
import { colors, FlexBox, ContentBlock } from '@argo/principles';
import { COMPONENT_STATE } from '../../constants/componentState';
import styled from '@emotion/styled';
import { validateNameInput, validatePhoneInput, validateEmailInput } from '../Utils/InputUtil';
import { InnerLabelInputField, Disclaimer, Button } from '@argo/ui-elements';

const RequiredField = styled.div`
  font-family: 'Open Sans', sans-serif;
  color: ${colors.primary.charcoal};
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.7px;
  margin-bottom: inherit;
`;

const stripDashes = (value) => value.replace(/-/g, '');

const hasNonNumericCharacters = (value) => /[^0-9]/gi.test(value);

const updatedFormatPhone = (value) => {
  if (value.length > 6) return value.replace(/^(\d{3})(\d{3})(\d+)$/, '$1-$2-$3');
  if (value.length > 3) return value.replace(/^(\d{3})(\d+)$/, '$1-$2');
  return value;
};

const ContactInformationBase = ({ handleContactInfoSelected, componentState }) => {
  const [errorState, setErrorState] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phoneNumber: false
  });

  const [isDisabled, setIsDisabled] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState('');

  const { formState, fieldOnChange } = useStepByStepFormUnlocker({
    mapping: {
      firstName: {
        index: 0,
        nonSequential: true,
        validator: (el) => {
          return validateNameInput(el.value);
        },
        defaultValue: ''
      },
      lastName: {
        index: 1,
        nonSequential: true,
        validator: (el) => {
          return validateNameInput(el.value);
        },
        defaultValue: ''
      },
      email: {
        index: 2,
        nonSequential: true,
        validator: (el) => {
          // can we broaden this? but note: the Email regex from Argo is faulty
          return validateEmailInput(el.value);
        },
        defaultValue: ''
      },
      phoneNumber: {
        index: 3,
        nonSequential: true,
        // validator: (el) => {
        //   return validatePhoneInput(el.value);
        // },
        validator: () => validatePhoneInput(phoneNumber), // When the time comes, revert this
        defaultValue: ''
      }
    }
  });

  // This is a workaround fix for "US456965-S&R: Appointment page - Phone Number Field Auto populate not working"
  // until the argo InnerLabelInputField (InputField) component can be fixed.
  // This workaround involves removing the phoneNumber input field from the argo utility useStepByStepFormUnlocker
  // and managing phoneNumber state within this component.
  // When the argo component fix is available, remove this workaround and add the phoneNumber into the StepByStepFormUnlocker
  // above (see previous versions of this file for the original code) in which useStepByStepFormUnlocker
  // manages phoneNumber state.

  // *** Begin workaround ***
  // const [inputPhoneNumber, setInputPhoneNumber] = useState('');
  // const [inputGuard, setInputGuard] = useState(false);
  // const [phoneNumberValid, setPhoneNumberValid] = useState(false);

  // useEffect(() => {
  //   if (inputGuard) {
  //     setTimeout(() => {
  //       setInputGuard(false);
  //     }, 1000);
  //   }
  // });

  // // This handler function is invoked whenever input is received from the argo InnerLabelInputField component
  // const inputChangeHandler = (event) => {
  //   const targetId = event.target.id;
  //   const targetValue = event.target.value;
  //   if (targetId === 'phoneNumber') {
  //     if (inputGuard) {
  //       // Ignore spurious input from InputField immediatedly after valid phone,
  //       // or just cause a re-render
  //       return;
  //     }

  //     if (validatePhoneInput(targetValue)) {
  //       // Valid phone number, so set state for valid phone number and input guard to ignore spurious input from InputField.
  //       setInputPhoneNumber(targetValue);
  //       setInputGuard(true);
  //       setPhoneNumberValid(true);
  //       setErrorState((prevErrorState) => ({
  //         ...prevErrorState,
  //         phoneNumber: false
  //       }));
  //     } else {
  //       // Invalid phone number so far
  //       // These next cases detect spurious digits returned from InputField. InputField is confused between what it thinks is
  //       // current input and what is displayed.
  //       if (inputPhoneNumber.length - targetValue.length > 1 && phoneNumberValid) {
  //         // These cases occur when the inputPhoneNumber is valid
  //         if (targetValue) {
  //           // We got some input
  //           if (targetValue.length === 1) {
  //             // user is typing a single character, possibly deleting a phone number with all digits selected
  //             setInputPhoneNumber(targetValue);
  //           } else {
  //             // We got multiple digits from input, meaning InputField component is confused. So ignore it.
  //             setInputPhoneNumber(inputPhoneNumber);
  //           }
  //         } else {
  //           // user has selected entire number and deleted it
  //           setInputPhoneNumber('');
  //         }
  //         // Force a re-render
  //         setInputGuard(true);
  //         return;
  //       }
  //       // The inputPhoneNumber is not valid
  //       if (targetValue.replace(/-|_/gi, '').length - inputPhoneNumber.replace(/-|_/gi, '').length > 1) {
  //         // Multiple digits received - ignore them
  //         // Note: this is done just to force a re-render to display what should be the correct phone number in inputPhoneNumber
  //         setInputPhoneNumber(inputPhoneNumber);
  //         setInputGuard(true);
  //         return;
  //       }
  //       // Single digit received, so process it.
  //       setPhoneNumberValid(false);
  //       setInputPhoneNumber(targetValue);
  //     }
  //   } else {
  //     // Not phone number, so invoke useStepByStepFormUnlocker.
  //     fieldOnChange(event);
  //   }
  // };
  // *** End workaround ***

  const validateFormInputs = () => {
    if (
      validateNameInput(formState.firstName.value) &&
      validateNameInput(formState.lastName.value) &&
      validateEmailInput(formState.email.value) &&
      // validatePhoneInput(formState.phoneNumber.value) // When the time comes, revert this
      validatePhoneInput(phoneNumber)
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };
  useEffect(() => {
    validateFormInputs();
  }, [formState, phoneNumber]); // When the time comes, revert this

  const handleFocusEvent = (e) => {
    setErrorState((prevErrorState) => ({
      ...prevErrorState,
      [e.target.id]: false
    }));
  };

  const updateErrorState = (e) => {
    const targetId = e.target.id;
    const targetValue = e.target.value;
    if (targetId.includes('Name')) {
      return setErrorState({
        ...errorState,
        [targetId]: !validateNameInput(targetValue)
      });
    }
    if (targetId.includes('email')) {
      return setErrorState({
        ...errorState,
        email: !validateEmailInput(targetValue)
      });
    }
    if (targetId.includes('phoneNumber')) {
      return setErrorState({
        ...errorState,
        phoneNumber: !validatePhoneInput(targetValue)
      });
    }
  };

  const handleBlurEvent = (e) => {
    return updateErrorState(e);
  };

  const handleOnChange = (e) => {
    fieldOnChange(e);
    if (e.target.id === document.activeElement.id) return;
    return updateErrorState(e);
  };

  // NOTE: This is a major hack to get around how the form mask is working, and should not be used
  //       long term.
  const handlePhoneChange = (event) => {
    const { value: rawValue } = event.target;
    const strippedValue = stripDashes(rawValue);

    if (hasNonNumericCharacters(strippedValue)) return;
    if (strippedValue.length > 10) return;

    const formattedValue = updatedFormatPhone(strippedValue);

    setPhoneNumber(() => formattedValue);
  };

  const onBookAppointment = (e) => {
    e.preventDefault();
    const contactInfo = {
      firstName: formState.firstName.value,
      lastName: formState.lastName.value,
      email: formState.email.value,
      // phone: formState.phoneNumber.value
      phone: phoneNumber // When the time comes, revert this
    };
    handleContactInfoSelected(contactInfo);
    if (typeof CAIEventBus !== 'undefined') {
      const adobeEvent = {
        reaxlName: 'adobeClickOnlyWebComponent',
        event: e,
        cmp: 'book_apt'
      };
      CAIEventBus.emit('serviceTab:adobeevent', {
        eventMessage: 'Book Appointment CTA has been clicked',
        eventData: adobeEvent
      });
    }
  };
  return (
    componentState.state === COMPONENT_STATE.active && (
      <form
        id="validateForm"
        noValidate
        onSubmit={onBookAppointment}
      >
        <ContentBlock verticalSpacing="xs" hideOverflow={true}>
          <ContentBlock verticalSpacing="xs">
            <div />
          </ContentBlock>
          <RequiredField data-testid="requiredField">*Required Fields</RequiredField>
          <FlexBox default={['1 1 50%', '1 1 50%', '1 1 50%', '1 1 50%']} flexWrap="wrap">
            <InnerLabelInputField
              data-testid="firstNameInput"
              autoFocus
              data-input-type="FirstName"
              id="firstName"
              labelText="First Name"
              data-field-index={formState.firstName.index}
              mask={null}
              maskPlaceholder="_"
              maskType={null}
              maxLength={null}
              noValidate
              onChange={handleOnChange}
              placeholder=""
              required
              requiredText=""
              type="text"
              width="auto"
              value={formState.firstName.value}
              error={errorState.firstName}
              errorText="Please enter a valid first name"
              onFocus={handleFocusEvent}
              onBlur={handleBlurEvent}
            />
            <InnerLabelInputField
              data-testid="lastNameInput"
              data-input-type="LastName"
              id="lastName"
              labelText="Last Name"
              data-field-index={formState.lastName.index}
              mask={null}
              maskPlaceholder="_"
              maskType={null}
              maxLength={null}
              noValidate
              onChange={handleOnChange}
              placeholder=""
              required
              requiredText=""
              type="text"
              width="auto"
              value={formState.lastName.value}
              error={errorState.lastName}
              errorText="Please enter a valid last name"
              onFocus={handleFocusEvent}
              onBlur={handleBlurEvent}
            />
            <InnerLabelInputField
              data-testid="emailInput"
              data-input-type="Email"
              id="email"
              labelText="Email Address"
              data-field-index={formState.email.index}
              mask=""
              maskPlaceholder="_"
              maskType={null}
              maxLength={null}
              noValidate
              onChange={handleOnChange}
              placeholder=""
              required
              requiredText=""
              type="email"
              width="auto"
              value={formState.email.value}
              error={errorState.email}
              errorText="Please enter a valid email"
              onFocus={handleFocusEvent}
              onBlur={handleBlurEvent}
            />
            <InnerLabelInputField
              data-testid="phoneNumberInput"
              data-input-type="PhoneNumber"
              data-field-index={formState.phoneNumber.index}
              name="phoneNumber"
              id="phoneNumber"
              labelText="Phone Number"
              mask="000-000-0000"
              maskPlaceholder=""
              maskType="PhoneNumber"
              // maskType={null} // When the time comes, revert this
              maxLength={null}
              noValidate
              onChange={handlePhoneChange}
              // onChange={handleOnChange} // When the time comes, revert this
              placeholder=""
              required
              requiredText=""
              type="tel"
              width="auto"
              value={phoneNumber}
              // value={formState.phoneNumber.value} // When the time comes, revert this
              // value={inputPhoneNumber}
              autoComplete="tel"
              error={errorState.phoneNumber}
              errorText="Please enter a valid phone number"
              onFocus={handleFocusEvent}
              onBlur={handleBlurEvent}
            />
          </FlexBox>
          <div>
            <Disclaimer data-testid="disclaimer">
              By clicking “Book Appointment”, I consent for Kelley Blue Book, Autotrader and the dealer(s) I’ve selected
              to call or text me at this number for marketing purposes, and I know you might use an autodialer or
              artificial/prerecorded voice. I know that consent is not required to use any services, and I can stop
              receiving your text messages by replying STOP. (Msg & data rates apply).
            </Disclaimer>
          </div>
          <FlexBox justifyContent="end" sm={['1']}>
            <Button
              data-testid="bookAppointmentButton"
              buttonType="primary"
              text="Book Appointment"
              minimizeSize={false}
              disabled={isDisabled}
              target="_self"
              rel="nofollow"
              isRemovePadding="false"
              enableCapitalize={true}
              sizeType="regular"
              width="auto"
              type="submit"
            />
          </FlexBox>
        </ContentBlock>
      </form>
    )
  );
};

export default ContactInformationBase;
