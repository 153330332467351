import React from 'react';
import { FlexBox, typography, colors } from '@argo/principles';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { COMPONENT_STATE } from '@cuiConstants';

const AnchorTagStyling = css`
  ${typography.defaults.XSMedium}
  position: relative;
  text-decoration: none;
  color: ${colors.action.darkBrightBlue};
`;

const StyledHeading = styled.h3`
  text-transform: none !important;
  ${(props) => props.labelTypography};
  color: ${colors.primary.black};
  margin-top: 0;
  margin-bottom: 0;
`;

const StyledPTag = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
`;

const StyledDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const FormSelectionEdit = ({
  icon,
  iconTestId,
  componentState = { state: COMPONENT_STATE.completed },
  completedText,
  defaultText,
  handleEdit,
  editIcon,
  labelTypography = typography.types.MSemi
}) => {
  const IconComponent = icon;
  const EditIcon = editIcon ?? null;
  const EditComponent = EditIcon ? (
    <EditIcon onClick={handleEdit} />
  ) : (
    <StyledPTag css={AnchorTagStyling} onClick={handleEdit}>
      Edit
    </StyledPTag>
  );

  return (
    <FlexBox alignItems="center">
      <IconComponent data-testid={iconTestId} />
      {componentState.state === COMPONENT_STATE.completed ? (
        <StyledDiv>
          <StyledHeading labelTypography={labelTypography} className="applitoolsLayoutOnly" headingTag="h3">
            {completedText}
          </StyledHeading>
          {EditComponent}
        </StyledDiv>
      ) : (
        <StyledHeading labelTypography={labelTypography} headingTag="h3">
          {defaultText}
        </StyledHeading>
      )}
    </FlexBox>
  );
};

export default FormSelectionEdit;
