import { gql } from '@apollo/client';

export default gql`
  query availableAppointments(
    $provider: String!
    $webCode: String!
    $svocId: String!
    $year: String
    $make: String
    $model: String
    $chromeStyleId: Int
    $vin: String
    $availableAppointmentsRequest: AvailableAppointmentsRequest
  ) {
    availableAppointments(
      provider: $provider
      webCode: $webCode
      svocId: $svocId
      year: $year
      make: $make
      model: $model
      chromeStyleId: $chromeStyleId
      vin: $vin
      availableAppointmentsRequest: $availableAppointmentsRequest
    ) {
      availableAppointments {
        appointmentDateTimeLocal
        durationMinutes
      }
      providerInfo
      status
      message
      serviceStatus
    }
  }
`;
