import React, { useState, useEffect } from 'react';
import ServiceCalendarTime from './ServiceCalendarTime';
import { formatDate, formatTime } from '../../utils/dateTimeUtils';
import ErrorDisplay from './ErrorDisplay';
import styled from '@emotion/styled';
import { Calendar } from '@argo/base-patterns';
import { FlexBox, typography, colors } from '@argo/principles';
import AppointmentSpinner from './AppointmentSpinner';

const StyledCalendar = styled(Calendar)`
  max-width: 380px;
  padding: 0px;
`;

const StyledSpinnerContainer = styled.div`
  width: 380px;
  @media (max-width: 440px) {
    width: 100%;
  }
`;

const StyledCalendarTimeContainer = styled.div`
  max-width: 380px;
`;

const StyledFlexBoxDateAndTime = styled(FlexBox)`
  width: 380px;
  @media (max-width: 440px) {
    width: 100%;
  }
`;

const StyledDateTimeText = styled.div`
  ${typography.types.XSExtra};
  color: ${colors.primary.black};
  cursor: pointer;
`;

const StyledSpan = styled.span`
  font-weight: 400;
`;

const ServiceCalendar = ({
  provider,
  webCode,
  setPostVariables,
  isEntryView,
  svocId,
  shortProvider,
  shortSubProvider,
  calendarSelectedDate,
  setCalendarSelectedDate,
  calendarSelectedTime,
  setCalendarSelectedTime,
  availableAppointmentData,
  getAvailableAppointments,
  vehicleInfo,
  selectedService,
  setEarliestAppointmentDate,
  setErrorState,
  dealerInfo,
  appointmentInfo,
  setVehicleHasChanged,
  earliestDateXTime
}) => {
  const [NextAppointmentDate, setNextAppointmentDate] = useState('');
  const [activateTimeSelection, setActivateTimeSelection] = useState(false);
  const [calendarSelectorMode, setCalendarSelectorMode] = useState('');
  const [dateHasChanged, setDateHasChanged] = useState(false);

  let divHeightstyle = {
    minHeight: 300,
    display: 'inline-block'
  };

  useEffect(() => {
    if (appointmentInfo?.startTime && appointmentInfo?.startDate) {
      setCalendarSelectedDate(appointmentInfo.startDate)
    }
  }, [appointmentInfo])

  useEffect(() => {
    if (availableAppointmentData?.data?.availableAppointments) {
      setPostVariables(availableAppointmentData?.data?.availableAppointments.providerInfo);
      if (
        (!availableAppointmentData?.data?.availableAppointments?.availableAppointments || availableAppointmentData?.data?.availableAppointments?.availableAppointments.length === 0) &&
        !availableAppointmentData.loading
      ) {
        setCalendarSelectorMode('error');
        return;
      }
        setVehicleHasChanged(false)
        getNextAppointmentDate(
          availableAppointmentData?.data?.availableAppointments?.availableAppointments[0]?.appointmentDateTimeLocal
        );
    }
  }, [availableAppointmentData]);

  const handleSelectDate = (date) => {
    let newDate = new Date(`${date.month + 1}/${date.date}/${date.year}`);

    setVehicleHasChanged(false)
    getAvailableAppointments({
      variables: {
        provider: provider,
        webCode: webCode,
        svocId: svocId.toString(),
        year: vehicleInfo.YearId.toString(),
        make: vehicleInfo.MakeName,
        model: vehicleInfo.ModelName,
        chromeStyleId: vehicleInfo.ChromeStyleId ? vehicleInfo.ChromeStyleId : undefined,
        availableAppointmentsRequest: {
          serviceName: provider.toLowerCase() === 'xtime' ? selectedService.xTimeServiceName : selectedService.serviceName,
          startDate: newDate ? new Date(newDate).toLocaleDateString() : new Date().toLocaleDateString(),
          uxServiceName: selectedService.serviceName
        }
      }
    });
    setActivateTimeSelection(true);
    setCalendarSelectedDate(newDate);
    setDateHasChanged(true);
  };

  let getNextAppointmentDate = (date) => {
      setNextAppointmentDate(date);
      setCalendarSelectedDate(new Date(date));
      if (appointmentInfo?.startTime) {
        setCalendarSelectedTime(appointmentInfo.startTime)
      } else {
        setCalendarSelectedTime(date);
      }
      setActivateTimeSelection(true);
      setCalendarSelectorMode('edit');
  };

  switch (calendarSelectorMode) {
    case 'edit':
      return (
        <div>
          <FlexBox
            horizontalSpacing="sm"
            verticalSpacing="sm"
            flexWrap="wrap"
            default={['0 0 50%', '0 0 50%']}
            sm={['0 0 100%', '0 0 100%']}
            md={['0 0 100%', '0 0 100%']}
          >
            <div>
              <div style={divHeightstyle}>
                <StyledFlexBoxDateAndTime justifyContent="space-between">
                  <StyledDateTimeText
                    data-testid="date-button"
                    onClick={() => {
                      setActivateTimeSelection(false);
                    }}
                  >
                    {`Date: `}
                    <StyledSpan style={{ fontWeight: 400 }}>
                      {calendarSelectedDate ? formatDate(calendarSelectedDate) : ''}
                    </StyledSpan>
                  </StyledDateTimeText>
                  <StyledDateTimeText
                    data-testid="time-button"
                    onClick={() => {
                      setActivateTimeSelection(true);
                    }}
                  >
                    {`Time: `}
                    <StyledSpan>{!dateHasChanged && calendarSelectedTime ? formatTime(calendarSelectedTime) : 'Select a Time'}</StyledSpan>
                  </StyledDateTimeText>
                </StyledFlexBoxDateAndTime>
                {activateTimeSelection ? (
                  availableAppointmentData.loading ? (
                    <StyledSpinnerContainer>
                      <AppointmentSpinner />
                    </StyledSpinnerContainer>
                  ) : (
                    <StyledCalendarTimeContainer data-testid="service-calendar-time">
                      <ServiceCalendarTime
                        shortProvider={shortProvider}
                        shortSubProvider={shortSubProvider}
                        availableAppointmentData={availableAppointmentData}
                        calendarSelectedDate={calendarSelectedDate}
                        calendarSelectedTime={calendarSelectedTime}
                        setCalendarSelectedTime={setCalendarSelectedTime}
                        setDateHasChanged={setDateHasChanged}
                      />
                    </StyledCalendarTimeContainer>
                  )
                ) : (
                  <StyledCalendar
                    data-testid="service-calendar"
                    defaultActiveDate={NextAppointmentDate ? new Date(NextAppointmentDate) : new Date()}
                    defaultDate={earliestDateXTime ? new Date(earliestDateXTime) : new Date()}
                    onSelectDate={handleSelectDate}
                    disablePastDates
                  ></StyledCalendar>
                )}
              </div>
            </div>
          </FlexBox>
        </div>
      );
    case 'error':
      setErrorState(true);
      return (
        <React.Fragment>
          <div>
            <br />
            <ErrorDisplay
              dataSection="cal"
              shortProvider={shortProvider}
              shortSubProvider={shortSubProvider}
              dealerInfo={dealerInfo}/>
          </div>
        </React.Fragment>
      );
    default:
      return (
        <StyledSpinnerContainer>
          <AppointmentSpinner />
        </StyledSpinnerContainer>
      );
  }
};
export default ServiceCalendar;
