import { Name as nameRegex } from '@argo/utils';

export const validateEmailInput = (value) => {
  if (!value) {
    return false;
  }

  const val = value.toLowerCase();
  return (
    val.indexOf('@') !== -1 &&
    (val.indexOf('.com') !== -1 ||
      val.indexOf('.net') !== -1 ||
      val.indexOf('.gov') !== -1 ||
      val.indexOf('.mil') !== -1 ||
      val.indexOf('.edu') !== -1)
  );
};

export const validateNameInput = (name) => {
  return name && nameRegex.test(name);
};
