import { useState, useCallback } from 'react';

const useSelectService = () => {
  const [selectedService, setSelectedService] = useState({
    serviceName: '',
    xTimeServiceName: '',
    comment: ''
  });

  // Invoked after user has selected a service and clicks CTA to update selectedService state
  // with values from passed in argument.
  // useCallback memoizes this function so that as a prop it will not change unless selectedService changes.
  const handleSelectedService = useCallback(
    (userSelectedService) => {
      setSelectedService(() => ({
        ...selectedService,
        ...userSelectedService
      }));
    }, [selectedService]
  );

  const handleResetSelectedService = () => {
    setSelectedService({});
  };

  return ({selectedService, handleSelectedService, handleResetSelectedService});
};

export default useSelectService;
