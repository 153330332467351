import React, { useRef, useState } from 'react';
import { colors, breakpoints, size } from '@argo/principles';
import styled from '@emotion/styled';
import { Box } from '@argo/ui-elements';
import PromoOverlay from './PromoOverlay';
import ddpDataAnalytics from '../../utils/ddpDataAnalytics';
import { PromoImage } from './PromoImage';
import { PromoTextElement } from './PromoTextElement';

const StyledBox = styled(Box)`
  border: 4px dashed ${colors.green.base};
  width: 422px;
  @media (max-width: ${breakpoints.xl}px) {
    width: 360px;
  }
  @media (max-width: ${breakpoints.lg}px) {
    width: 100%;
  }
  &:hover {
    cursor: pointer;
  }
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${size.sm}px;
`;

const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
`;

const DealerOffers = ({ dealerInfo, shadowRoot, ddpDataLayer, ddpPixallDataLayer, displayedDealer }) => {
  const [showPromoOverlay, setShowPromoOverlay] = useState(false);
  const offerDetails = useRef('');
  const { provider, svocId, type, serviceCentersPromotions } = displayedDealer;
  const [{ imageUrl, tagLine, description, disclaimer }] = serviceCentersPromotions || [{}];

  // Why? :(
  if ('serviceCentersOffers' in displayedDealer) {
    offerDetails.current = displayedDealer.serviceCentersOffers;
  }

  const handleOverlayClose = () => {
    setShowPromoOverlay(false);
  };

  const handleDealerOffersClick = (event) => {
    event.preventDefault();
    ddpDataAnalytics.fireDdpClick(
      ddpDataLayer,
      ddpDataAnalytics.clicks.promoTextClick(offerDetails.current, svocId, provider, type)
    );
    if (typeof CAIEventBus !== 'undefined') {
      const adobeEvent = {
        reaxlName: 'adobeClickOnlyWebComponent',
        event: event,
        cmp: 'dlr-promo_lnk'
      };
      CAIEventBus.emit('serviceTab:adobeevent', {
        eventMessage: 'Dealer Offers CTA has been clicked',
        eventData: adobeEvent
      });
    }
    setShowPromoOverlay(true);
  };

  return (
    <>
      {showPromoOverlay && (
        <PromoOverlay
          show={showPromoOverlay}
          coupon={offerDetails.current}
          dealerInfo={displayedDealer}
          onClose={handleOverlayClose}
          shadowRoot={shadowRoot}
          ddpDataLayer={ddpDataLayer}
          ddpPixallDataLayer={ddpPixallDataLayer}
        />
      )}
      <StyledBox backgroundColor="offWhite" contentPadding="small" responsive onClick={handleDealerOffersClick}>
        <StyledContentContainer data-cy="dealerOffers">
          <StyledDiv>
            <PromoTextElement text={tagLine || offerDetails.current} variant="tagline" />
            <PromoImage imageUrl={imageUrl} />
            <PromoTextElement text={description} variant="description" />
            <PromoTextElement text={disclaimer} variant="disclaimer" />
          </StyledDiv>
        </StyledContentContainer>
      </StyledBox>
    </>
  );
};

export default DealerOffers;
