import React, { useState, useCallback, useEffect } from 'react';
import { COMPONENT_STATE } from './constants/componentState';
import {
  useSelectDateTime,
  useSelectVehicle,
  useSelectService,
  useSelectContactInfo,
  useStateManagement
} from './hooks';
import { ContentBlock } from '@argo/principles';
import SelectVehicle from './SelectVehicle';
import SelectService from './SelectService';
import SelectDateTime from './SelectDateTime';
import ContactInformation from './ContactInformation';
import AppointmentConfirmation from './AppointmentConfirmation';
import AppointmentLoader from './AppointmentLoader';
import ErrorDisplay from './SelectDateTime/DateTimeBase/ErrorDisplay';
import { Theme } from '@argo/principles';
import availableAppointmentsQuery from './tags/queries/availableAppointments';
import { useLazyQuery } from '@apollo/client';
import { getDefaultVehicle } from './utils/getDefaultVehicle';
import { friendlyUrl } from '../utils';
import { getCrmDaysOutOffset, getFirstAvailableApptDateFromNow } from './utils/dateTimeUtils';
import { getWinDataLayer, getWinPixallDataLayer } from '@kbbhydra/utils';

const ScheduleService = ({
  dealerInfo,
  client,
  displayedDealer,
  setSelectedMake,
  displayedMakes,
  setEarliestAppointmentDate,
  commonData,
  source
}) => {
  // Use custom hooks to set up state + state handler associated with each individual child component
  // in the book appointment sequence.
  const { vehicleInfo, handleSelectedVehicleInfo } = useSelectVehicle();
  const { selectedService, handleSelectedService, handleResetSelectedService } = useSelectService();
  const { appointmentInfo, handleSelectedDateTime, handleResetSelectedDateTime } = useSelectDateTime();
  const { contactInfo, appointmentConfirmed, handleSelectedContactInfo } = useSelectContactInfo();
  const { stateSequencer, getComponentState, getNextComponentState, resetDownstreamData } = useStateManagement(
    handleResetSelectedService,
    handleResetSelectedDateTime
  );
  const [calendarSelectedDate, setCalendarSelectedDate] = useState('');
  const [calendarSelectedTime, setCalendarSelectedTime] = useState('');
  const [defaultVehicleInfo, setDefaultVehicleInfo] = useState();
  const [loading, setLoading] = useState(true);
  const [vehicleHasChanged, setVehicleHasChanged] = useState(false);
  const [earliestDateXTime, setEarliestDateXTime] = useState('');
  const [successfulSchedule, setSuccessfulSchedule] = useState(true);

  // Represents current child component state.
  // Causes this main parent sequencer component to rerender on every child component state change.
  const [currentStateIndex, setCurrentStateIndex] = useState(0);
  const [currentState, setCurrentState] = useState(COMPONENT_STATE.inactive);

  const { items, makes, svocId } = dealerInfo;
  // Process the state change signaled by a book appointment child component.
  // changeStateHandler is passed in as a prop to each book appointment child component.
  // It is memoized using useCallback with no dependencies,
  // so it will never be recreated as a new function when the parent is rerendered.
  // Therefore, it alone will never contribute to a child component rerender.
  const changeStateHandler = useCallback((newState) => {
    // If new child component state is active, then find any other child component state that is active and change it to inactive.
    // There can be at most 1 component in the active state at any time.
    // This case occurs if we click an edit button on a previously completed component forcing it to active state.
    // For example, we are on contact information child component, but then user clicks the edit button for a previously selected vehicle.

    if (newState.state === COMPONENT_STATE.active) {
      const currentlyActiveComponent = stateSequencer.current.find((component) => {
        return component.state === COMPONENT_STATE.active;
      });
      currentlyActiveComponent.state = COMPONENT_STATE.inactive;
      resetDownstreamData(newState.name);
    }
    // Now process current state change
    const { foundIndex, foundState } = getComponentState(newState.name);
    foundState.state = newState.state;
    // If current state completed, then get next state and change it to active

    if (newState.state === COMPONENT_STATE.completed) {
      resetDownstreamData(newState.name);
      const nextState = getNextComponentState(foundIndex);
      nextState.state = COMPONENT_STATE.active;
    }

    // This causes the parent to rerender if either the state index changes or the state itself changes
    setCurrentStateIndex(foundIndex);
    setCurrentState(foundState.state);
  }, []);

  //each child component will pass componentState, changeStateHandler, selectedHandler
  //ex. componentState={getComponentState('select_vehicle').currentComponentState}, changeStateHandler={changeStateHandler}, selectedHandler={handleSelectedVehicleInfo}
  //select service will also pass in vehicleInfo and zipCode

  let shortProvider = displayedDealer.provider ? displayedDealer.provider.substring(0, 3).toLowerCase() : '';
  let shortSubProvider = displayedDealer.subProvider ? displayedDealer.subProvider.substring(0, 3).toLowerCase() : '';

  const ddpDataLayer = getWinDataLayer();
  const ddpPixallDataLayer = getWinPixallDataLayer();

  const [getAvailableAppointments, availableAppointmentData] = useLazyQuery(availableAppointmentsQuery, {
    ssr: false,
    onCompleted: () => {
      if (defaultVehicleInfo || vehicleHasChanged) {
        handleEarliestAppointmentDate();
      }
    },
    client,
    context: {
      headers: {
        'query-type': 'available-appointments'
      }
    }
  });

  const handleGetDefaultVehicle = async () => {
    const vehicleData = await getDefaultVehicle(displayedMakes[0], client);
    setDefaultVehicleInfo(vehicleData);
  };

  useEffect(() => {
    if (
      vehicleInfo?.YearId &&
      vehicleInfo?.MakeName &&
      vehicleInfo?.ModelName &&
      selectedService.serviceName &&
      displayedDealer?.provider.toLowerCase() === 'xtime'
    ) {
      setDefaultVehicleInfo(null);
      setVehicleHasChanged(true);
      getAvailableAppointments({
        variables: {
          provider: displayedDealer.provider,
          webCode: displayedDealer.webCode,
          svocId: displayedDealer.svocId.toString(),
          year: vehicleInfo.YearId.toString(),
          make: vehicleInfo.MakeName,
          model: vehicleInfo.ModelName,
          chromeStyleId: vehicleInfo.ChromeStyleId ? vehicleInfo.ChromeStyleId : undefined,
          availableAppointmentsRequest: {
            serviceName:
              displayedDealer.provider.toLowerCase() === 'xtime'
                ? selectedService.xTimeServiceName
                : selectedService.serviceName,
            startDate: new Date().toLocaleDateString(),
            uxServiceName: selectedService.serviceName
          }
        }
      });
    } else if (
      (vehicleInfo?.YearId &&
        vehicleInfo?.MakeName &&
        vehicleInfo?.ModelName &&
        selectedService.serviceName &&
        displayedDealer?.provider.toLowerCase() === 'crm') ||
      displayedDealer?.provider.toLowerCase() === 'emailonly'
    ) {
      handleEarliestAppointmentDate();
    } else {
      handleGetDefaultVehicle();
    }
  }, [vehicleInfo, selectedService]);

  useEffect(() => {
    if (defaultVehicleInfo && displayedDealer?.provider.toLowerCase() === 'xtime') {
      getAvailableAppointments({
        variables: {
          provider: displayedDealer.provider,
          webCode: displayedDealer.webCode,
          svocId: displayedDealer.svocId.toString(),
          year: defaultVehicleInfo.defaultYear.yearId.toString(),
          make: friendlyUrl(defaultVehicleInfo.defaultMake.text),
          model: friendlyUrl(defaultVehicleInfo.defaultModel.text),
          chromeStyleId: defaultVehicleInfo.defaultChromeStyleId ? defaultVehicleInfo.defaultChromeStyleId : undefined,
          availableAppointmentsRequest: {
            serviceName:
              displayedDealer.provider.toLowerCase() === 'xtime'
                ? selectedService.xTimeServiceName
                : selectedService.serviceName,
            startDate: new Date().toLocaleDateString(),
            uxServiceName: selectedService.serviceName
          }
        }
      });
    } else if (
      (defaultVehicleInfo && displayedDealer?.provider.toLowerCase() === 'crm') ||
      displayedDealer?.provider.toLowerCase() === 'emailonly'
    ) {
      handleEarliestAppointmentDate();
    }
  }, [defaultVehicleInfo]);

  const handleEarliestAppointmentDate = () => {
    if (displayedDealer?.provider.toLowerCase() === 'xtime' && availableAppointmentData?.data?.availableAppointments) {
      if (
        !availableAppointmentData?.data?.availableAppointments?.availableAppointments &&
        !availableAppointmentData.loading
      ) {
        setEarliestAppointmentDate('No available appointments');
        return;
      }
      getEarliestAppointmentDate(
        availableAppointmentData?.data?.availableAppointments?.availableAppointments[0]?.appointmentDateTimeLocal
      );
    } else if (
      displayedDealer?.provider.toLowerCase() === 'crm' ||
      displayedDealer?.provider.toLowerCase() === 'emailonly'
    ) {
      getEarliestCRMAppointmentDate();
    }
  };

  const getEarliestAppointmentDate = (date) => {
    setEarliestAppointmentDate(date);
    setEarliestDateXTime(date);
  };

  const getEarliestCRMAppointmentDate = () => {
    const firstAppointmentDate = getFirstAvailableApptDateFromNow({
      daysOutOffset: getCrmDaysOutOffset(displayedDealer)
    });
    setEarliestAppointmentDate(firstAppointmentDate);
  };

  return (
    <Theme noObservable>
      {!appointmentConfirmed ? (
        <ContentBlock data-cy="scheduleService">
          <SelectVehicle
            componentState={getComponentState('select_vehicle').currentComponentState}
            changeStateHandler={changeStateHandler}
            selectedHandler={handleSelectedVehicleInfo}
            client={client}
            makes={displayedMakes}
            setSelectedMake={setSelectedMake}
            ddpDataLayer={ddpDataLayer}
            ddpPixallDataLayer={ddpPixallDataLayer}
            svocId={displayedDealer?.svocId}
          />
          <SelectService
            componentState={getComponentState('select_service').currentComponentState}
            changeStateHandler={changeStateHandler}
            selectedHandler={handleSelectedService}
            ddpDataLayer={ddpDataLayer}
            vehicleInfo={vehicleInfo}
            displayedDealer={displayedDealer}
          />
          <SelectDateTime
            componentState={getComponentState('select_datetime').currentComponentState}
            changeStateHandler={changeStateHandler}
            selectedHandler={handleSelectedDateTime}
            dealerInfo={displayedDealer}
            shortProvider={shortProvider}
            shortSubProvider={shortSubProvider}
            vehicleInfo={vehicleInfo}
            selectedService={selectedService}
            availableAppointmentData={availableAppointmentData}
            getAvailableAppointments={getAvailableAppointments}
            calendarSelectedDate={calendarSelectedDate}
            calendarSelectedTime={calendarSelectedTime}
            setCalendarSelectedDate={setCalendarSelectedDate}
            setCalendarSelectedTime={setCalendarSelectedTime}
            setEarliestAppointmentDate={setEarliestAppointmentDate}
            appointmentInfo={appointmentInfo}
            setVehicleHasChanged={setVehicleHasChanged}
            earliestDateXTime={earliestDateXTime}
            ddpDataLayer={ddpDataLayer}
          />
          <ContactInformation
            componentState={getComponentState('select_contact').currentComponentState}
            changeStateHandler={changeStateHandler}
            selectedHandler={handleSelectedContactInfo}
            vehicleInfo={vehicleInfo}
            contactInfo={contactInfo}
            selectedService={selectedService}
            displayedDealer={displayedDealer}
            calendarSelectedTime={calendarSelectedTime}
            availableAppointmentData={availableAppointmentData}
            client={client}
            commonData={commonData}
            source={source}
            ddpDataLayer={ddpDataLayer}
            ddpPixallDataLayer={ddpPixallDataLayer}
            setSuccessfulSchedule={setSuccessfulSchedule}
            setLoading={setLoading}
          />
        </ContentBlock>
      ) : loading ? (
        <AppointmentLoader
          vehicleInfo={vehicleInfo}
          contactInfo={contactInfo}
          selectedService={selectedService}
          displayedDealer={displayedDealer}
          calendarSelectedTime={calendarSelectedTime}
          ddpDataLayer={ddpDataLayer}
        />
      ) : successfulSchedule ? (
        <AppointmentConfirmation
          vehicleInfo={vehicleInfo}
          contactInfo={contactInfo}
          selectedService={selectedService}
          displayedDealer={displayedDealer}
          calendarSelectedTime={calendarSelectedTime}
          ddpDataLayer={ddpDataLayer}
        />
      ) : (
        <ErrorDisplay
          dealerInfo={displayedDealer}
          dataSection="cal"
          shortProvider={shortProvider}
          shortSubProvider={shortSubProvider}
        />
      )}
    </Theme>
  );
};

export default ScheduleService;
