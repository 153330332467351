/* eslint-disable no-restricted-syntax */
import stringSimilarity from 'string-similarity';
import { Name as nameRegex } from '@argo/utils';

export const validateEmailInput = (value) => {
  if (!value) {
    return false;
  }

  const val = value.toLowerCase();
  return (
    val.indexOf('@') !== -1 &&
    (val.indexOf('.com') !== -1 ||
      val.indexOf('.net') !== -1 ||
      val.indexOf('.gov') !== -1 ||
      val.indexOf('.mil') !== -1 ||
      val.indexOf('.edu') !== -1)
  );
};

export const validateNameInput = (name) => {
    return name && nameRegex.test(name);
};

export const validatePhoneInput = (phone) => {
  return phone && phone.replace(/-|_/gi, '').length === 10;
};

export const normalizeVinInput = (value) => {
  return (value || '').replace(/[^A-HJ-NP-PR-Za-hj-np-prz0-9]/gi, '') || '';
};

// removes everything except alpha-numeric characters and single spaces
export const normalizeName = (name) => {
  if (!name) {
    return name;
  }
  return name
    .toLowerCase()
    .replace(/[^A-Za-z0-9\s]/g, '')
    .replace(/\s+/g, ' ');
};

// true if both strings are greater than 3 characters
// and any word in stringA is similar to any word in stringB
const containsSimilarWords = (stringA, stringB, wordSimilarityThreshold) => {
  if (!(stringA?.length > 3 && stringB?.length > 3)) {
    return false;
  }
  const wordsA = stringA.split(' ');
  const wordsB = stringB.split(' ');
  for (const a of wordsA) {
    for (const b of wordsB) {
      if (stringSimilarity.compareTwoStrings(a, b) >= wordSimilarityThreshold) {
        return true;
      }
    }
  }
  return false;
};

const similaritySort = (array, similarityDict, filterKey) => {
  return array.sort((a, b) => (similarityDict[a[filterKey] || a] > similarityDict[b[filterKey] || b] ? -1 : 1));
};

// returns a list of objects filtered based on the given input using word similiarity
export const filterListFromInput = ({
  list,
  input,
  filterKey = 'name',
  wordSimilarityThreshold = 0.75,
  listIsNormalized = false,
  sortBySimilarity = true
}) => {
  const normInput = normalizeName(input);
  const similarityDict = {};

  const filteredData = list?.reduce((res, item) => {
    const itemKey = item[filterKey] || (typeof item !== 'object' && item);
    const normItemKey = listIsNormalized ? itemKey : normalizeName(itemKey);

    // keep if item includes input substring
    if (normItemKey && normItemKey.includes(normInput)) {
      if (sortBySimilarity) {
        similarityDict[itemKey] = 1;
      }
      res.push(item);
    }

    // otherwise keep if any word in the item is similar to any word in the input
    else if (containsSimilarWords(normItemKey, normInput, wordSimilarityThreshold)) {
      if (sortBySimilarity) {
        similarityDict[itemKey] = stringSimilarity.compareTwoStrings(normItemKey, normInput);
      }
      res.push(item);
    }

    // otherwise filter out item
    return res;
  }, []);

  // sort by similarity
  if (sortBySimilarity) {
    similaritySort(filteredData, similarityDict, filterKey);
  }

  return filteredData;
};
