const toGtmString = (value) => (value ? value.toString() : undefined);

const ddpDataAnalytics = {
  getEvent: (event) => {
    return {
      ...event
    };
  },

  fireDdpClick: (dataLayer, event) => {
    dataLayer.push({
      ...ddpDataAnalytics.getEvent(event)
    });
  },

  defaultValues: {
    vehicle: {
      catalog: 'kbb'
    }
  },

  mapVehicleInfo: (vehicleInfo) => {
    return {
      year: toGtmString(vehicleInfo?.YearId),
      makeId: toGtmString(vehicleInfo?.MakeId),
      makeName: toGtmString(vehicleInfo?.MakeName),
      modelId: toGtmString(vehicleInfo?.ModelId),
      modelName: toGtmString(vehicleInfo?.ModelName),
      trimName: toGtmString(vehicleInfo?.TrimName),
      vehicleId: toGtmString(vehicleInfo?.VehicleId),
      vin: toGtmString(vehicleInfo?.Vin),
      chromeStyleId:
        vehicleInfo?.ChromeStyleIds && vehicleInfo?.ChromeStyleIds.length > 0
          ? toGtmString(vehicleInfo.ChromeStyleIds[0])
          : undefined
    };
  },

  constructVehicle: (vehicleInfo = null, svocid = null, provider = null, subProvider = null, type = null, defaultValues = false) => {
    const defaults = defaultValues ? ddpDataAnalytics.defaultValues.vehicle : {};
    const vehicle = vehicleInfo ? ddpDataAnalytics.mapVehicleInfo(vehicleInfo) : {};
    return {
      ...defaults,
      ...vehicle,
      dealershipId: toGtmString(svocid),
      customerType: provider && provider.toLowerCase(),
      priorityType: type
    };
  },

  constructEcomData(
    ecomIdName = null,
    ecomList = null,
    type = null,
    provider = null,
    svocId = null,
    distance = null,
    serviceIndex = null
  ) {
    return {
      id: ecomIdName,
      name: ecomIdName,
      category: 'ecom_service-repair',
      list: ecomList,
      ...(serviceIndex && { dimension25: serviceIndex }),
      dimension31: type,
      dimension32: provider && provider.toLowerCase(),
      dimension33: svocId,
      dimension34: distance
    };
  },
  clicks: {
    selectVehicleDdp: (vehicle) => {
      return {
        event: 'analytics_event',
        eventElement: 'service',
        eventAction: 'click',
        eventResult: 'cta::select-vehicle',
        eventNonInteractive: true,
        eventData: {
          vehicle: ddpDataAnalytics.constructVehicle(vehicle, null, null, null, null, true)
        }
      };
    },
    selectVehicleServiceApptClickDdp: () => {
      return {
        event: 'analytics_event',
        eventElement: 'service',
        eventAction: 'click',
        eventResult: 'cta::service-category::next',
        eventNonInteractive: true,
        eventData: {
          vehicle: null
        }
      };
    },
    saveServiceAppointmentClickDdp: () => {
      return {
        event: 'analytics_event',
        eventElement: 'service',
        eventAction: 'click',
        eventResult: 'cta::service-appt::save',
        eventNonInteractive: true,
        eventData: {
          vehicle: null
        }
      };
    },
    bookServiceAppointmentClickDdp: (svocid, provider, type) => {
      return {
        event: 'analytics_event',
        eventElement: 'service',
        eventAction: 'submit',
        eventResult: 'lead::service-appt',
        eventNonInteractive: true,
        eventData: {
          vehicle: ddpDataAnalytics.constructVehicle(null, svocid, provider, null, type)
        }
      };
    },
    serviceCategoryEcomClick: (ecomIdName, type, provider, svocId, distance, serviceIndex) => {
      return {
        event: 'ecom_event',
        eventElement: 'service-and-repair',
        eventAction: 'ecom-click',
        eventResult: 'cta::service-category::next',
        eventNonInteractive: true,
        ecommerce: {
          click: {
            actionField: {
              list: 'ecom_service-repair'
            },
            products: [ddpDataAnalytics.constructEcomData(ecomIdName, null, type, provider, svocId, distance, serviceIndex)]
          }
        }
      };
    }
    //
  }
};

export default ddpDataAnalytics;
