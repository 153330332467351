import { gql } from '@apollo/client';

export default gql`
  query modelList($yearId: String, $makeId: String) {
    list: models(makeId: $makeId, yearId: $yearId) {
      modelName
      modelId
      text: modelName
      value: modelId
    }
  }
`;
