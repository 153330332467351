import { InMemoryCache } from '@apollo/client/cache';
import { makeVar } from '@apollo/client';

export const vehicleInfoVar = makeVar('');

export const localStateVars = {
  vehicleInfoVar,
  apptQueryStatusVar: makeVar('')
};

export const localState = {
  addTypename: true,
  typePolicies: {
    Query: {
      fields: {
        vehicleInfo: {
          read() {
            return localStateVars.vehicleInfoVar();
          }
        },
        apptQueryStatus: {
          read() {
            return localStateVars.apptQueryStatusVar();
          }
        }
      }
    }
  }
};

const cache =
  typeof window === 'undefined'
    ? new InMemoryCache(localState)
    : new InMemoryCache(localState).restore(window.__APOLLO_STATE__); // eslint-disable-line no-underscore-dangle

export default cache;
