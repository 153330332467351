import React from 'react';
import { GridContainer, typography, colors, size, breakpoints } from '@argo/principles';
import styled from '@emotion/styled';
import OpenOrCloseStatus from './OpenOrCloseStatus';
import { Paragraph } from '@argo/ui-elements';
import { Expander } from '@argo/base-patterns';

const StyledGridContainer = styled(GridContainer)`
  grid-template-columns: auto;
  width: 100%;
  padding: ${size.xs}px;
  margin: 0;
  align-items: center;
`;

const StyledDiv = styled.div`
  ${typography.types.body};
  color: ${colors.primary.black};
  display: flex;
  gap: ${size.xs}px;
  align-items: center;
  width: 100%;
  justify-content:space-between;
`;

const StyledDots = styled.div`
  width: 100%; 
  border-top: 2px dotted ${colors.neutral.trueGray};
`;

const StyledParagraph = styled(Paragraph)`
  display: flex;
  ${typography.types.body};
  color: ${colors.primary.black};
  align-items:center;
  white-space: nowrap;
`;

const ServiceCenterHoursComponent = ({serviceCenterHours, hoursOfOperation, formatOperatingHours }) => {
  return (
    <>
      {serviceCenterHours.length > 0 && (
        <OpenOrCloseStatus serviceCenterHours={serviceCenterHours} />
      )}
      {hoursOfOperation.map((item, index) => (
        <StyledDiv key={index} >
          <StyledParagraph>{item.day}</StyledParagraph>
            <StyledDots />
          <StyledParagraph>{formatOperatingHours(item.openTime, item.closeTime)}</StyledParagraph>
        </StyledDiv>
      ))}
    </>
  );
}

const ServiceCenterHoursOfOperation = ({ displayedDealer, isMobileViewport }) => {
  const { serviceCentersDepartments } = displayedDealer; 
  const serviceCenterHours =  serviceCentersDepartments?.filter((s) => s.departmentName === 'Service');

  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const hoursOfOperation = serviceCenterHours.length > 0 ? daysOfWeek.map((d) => ({
    day: d,
    openTime: serviceCenterHours[0][`${d.toLowerCase()}OpenHour`],
    closeTime: serviceCenterHours[0][`${d.toLowerCase()}CloseHour`]
  })) : [];
  
  const formatOperatingHours = (open, close) => (open != 'Closed' && close != 'Closed') 
    ? `${open} - ${close}` 
    : 'Closed' ;

    const renderMobileView = () => {
    return (
      <>
        <Expander
          isInverse={true}
          isWideExpander={false}
          wideLinkExpander={false}
          viewMoreText={'Read More'}
          viewLessText={'Read Less'}
          verticalSpacing={'0px'}
          isExpand={false}
          hideOverflow={true}
          maxLines={1}
        >
        <ServiceCenterHoursComponent 
          serviceCenterHours={serviceCenterHours} 
          hoursOfOperation={hoursOfOperation}
          formatOperatingHours={formatOperatingHours} />
      </Expander>
    </>
    )
  };

  const renderDeskTopView = () => {
    return ( 
      <StyledGridContainer columnGap="md"  gridTemplateColumns= 'auto 1fr' >
        <ServiceCenterHoursComponent 
          serviceCenterHours={serviceCenterHours} 
          hoursOfOperation={hoursOfOperation}
          formatOperatingHours={formatOperatingHours} />
      </StyledGridContainer>
    );
  }; 

  if (serviceCenterHours.length === 0){
    return null;
  };

  return <>{(isMobileViewport) ? renderMobileView() : renderDeskTopView()}</>
};

export default ServiceCenterHoursOfOperation;