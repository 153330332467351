/* eslint-disable prefer-destructuring */
import { friendlyUrl } from '../../utils';
import yearsQuery from '../tags/queries/years';
import makesQuery from '../tags/queries/makes';
import modelsQuery from '../tags/queries/models';
import trims2Query from '../tags/queries/trims2';
import vehicleQuery from '../tags/queries/vehicle';

export const getDefaultVehicle = async (make, client) => {
  //  getting default vehicle data
  // sixth year from current year
  // first make in list
  // first model in list
  // first trim in list
  try {
    const yearsResult = await client.query({
      query: yearsQuery,
      variables: {},
      ssr: true
    });
    let defaultYear;
    if (yearsResult?.data?.list.length >= 7) {
      defaultYear = yearsResult?.data?.list[6];
    } else {
      defaultYear = yearsResult?.data?.list[yearsResult?.data?.list.length - 1];
    }
    let defaultMake;
    if (defaultYear?.value) {
      const yearId = defaultYear.value;
      const makesResult = await client.query({
        query: makesQuery,
        variables: {
          yearId
        }
      });
      if (make) {
        const filteredMakes = makesResult.data.list.filter(
          (m) => friendlyUrl(m.text.toLowerCase()) === friendlyUrl(make.toLowerCase())
        );

        if (filteredMakes && filteredMakes.length > 0) {
          defaultMake = filteredMakes[0];
        }
      }
    }
    let defaultModel;
    if (defaultYear?.value && defaultMake?.value) {
      const yearId = defaultYear.value;
      const makeId = defaultMake.value;
      const modelsResult = await client.query({
        query: modelsQuery,
        variables: {
          yearId,
          makeId
        }
      });
      if (modelsResult?.data?.list) {
        defaultModel = modelsResult.data.list[0];
      }
    }
    let defaultTrim;
    if (defaultYear?.value && defaultMake?.value && defaultModel?.value) {
      const yearId = defaultYear.value;
      const makeId = defaultMake.value;
      const modelId = defaultModel.value;
      const trimsResult = await client.query({
        query: trims2Query,
        variables: {
          yearId,
          makeId,
          modelId
        }
      });
      if (trimsResult?.data?.list) {
        defaultTrim = trimsResult.data.list[0];
      }
    }
    let defaultChromeStyleId;
    if (defaultYear?.value && defaultTrim?.value) {
      const year = defaultYear.value;
      const vehicleid = defaultTrim.value;
      const modelQueryResult = await client.query({
        query: vehicleQuery,
        variables: {
          vehicleid,
          year,
          make: defaultMake?.text ? friendlyUrl(defaultMake.text) : '',
          model: defaultModel?.text ? friendlyUrl(defaultModel.text) : ''
        }
      });

      if (modelQueryResult?.data?.vehicle?.chromeStyleIds) {
        defaultChromeStyleId = modelQueryResult.data.vehicle.chromeStyleIds[0];
      }
    }

    const vehicleInfo = {
      defaultYear,
      defaultMake,
      defaultModel,
      defaultTrim,
      defaultChromeStyleId
    };
    return vehicleInfo;
  } catch (e) {
    console.log(`Exception ${e}`);
  }
};
