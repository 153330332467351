import React from 'react';
import { typography, colors, size } from '@argo/principles';
import styled from '@emotion/styled';
import { Paragraph } from '@argo/ui-elements';

const StyledHeader = styled.h4`
  ${typography.types.SExtra};
  color: ${colors.primary.black};
  text-align: left;
  margin-bottom: ${size.xs}px;
`;

const StyledParagraph = styled(Paragraph)`
  display: flex;
  ${typography.types.body};
  color: ${colors.primary.black};
  align-items:center;
`;

const AboutServiceCenter = ({ displayedDealer }) => {
  const { aboutServiceCenter } = displayedDealer;

  if (!aboutServiceCenter){
    return null;
  }
  return (
    <div data-cy="AboutServiceCenter">
      <StyledHeader>About Our Service Department</StyledHeader>       
      <StyledParagraph>{aboutServiceCenter}</StyledParagraph>
    </div>
  )
}; 

export default AboutServiceCenter;
