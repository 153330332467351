import React, { useRef } from 'react';
import { FlexBox } from '@argo/principles';
import { COMPONENT_STATE } from '../constants/componentState';
import DateTimeBase from './DateTimeBase';
import { Box } from '@argo/ui-elements';
import { DateTimeIcon } from '@argo/ui-elements';
import styled from '@emotion/styled';
import { formatDate, formatTime } from '../utils/dateTimeUtils';
import SectionTitle from '../shared/SectionTitle';
import ddpDataAnalytics from '../utils/ddpDataAnalytics';

const StyledBox = styled(Box)`
  min-height: 100%;
`;

const SelectDateTime = ({
  componentState,
  changeStateHandler,
  selectedHandler,
  dealerInfo,
  shortProvider,
  shortSubProvider,
  vehicleInfo,
  selectedService,
  availableAppointmentData,
  getAvailableAppointments,
  calendarSelectedDate,
  calendarSelectedTime,
  setCalendarSelectedDate,
  setCalendarSelectedTime,
  setEarliestAppointmentDate,
  appointmentInfo,
  setVehicleHasChanged,
  earliestDateXTime,
  ddpDataLayer
}) => {
  const selectedDateTimeInfo = useRef({
    startDate: '',
    startTime: '',
    providerInfo: '',
    statusMessage: '',
    displayText: ''
  });

  const handleDateTimeSelected = (dateTimeData) => {
    ddpDataAnalytics.fireDdpClick(ddpDataLayer, ddpDataAnalytics.clicks.saveServiceAppointmentClickDdp(vehicleInfo));

    selectedDateTimeInfo.current = dateTimeData;
    selectedHandler(selectedDateTimeInfo.current);

    selectedDateTimeInfo.current.displayText = `${formatDate(new Date(selectedDateTimeInfo?.current?.startDate))} ${formatTime(
      selectedDateTimeInfo?.current?.startTime
    )}`
    changeStateHandler({ name: componentState.name, state: COMPONENT_STATE.completed });
  };

  const handleEditDateTime = () => {
    changeStateHandler({ name: componentState.name, state: COMPONENT_STATE.active });
  };

  return (
    <StyledBox backgroundColor="white" response={false} shadow={false} width={'100%'}>
      <FlexBox flowColumn verticalSpacing="sm">
        <SectionTitle
          icon={DateTimeIcon}
          iconTestId="dateTimeIcon"
          componentState={componentState}
          completedText={selectedDateTimeInfo?.current?.displayText}
          defaultText="Your Date & Time"
          handleEdit={handleEditDateTime}
        />
        <DateTimeBase
          handleDateTimeSelected={(dateTimeData) => handleDateTimeSelected(dateTimeData)}
          componentState={componentState}
          dealerInfo={dealerInfo}
          shortProvider={shortProvider}
          shortSubProvider={shortSubProvider}
          vehicleInfo={vehicleInfo}
          selectedService={selectedService}
          calendarSelectedDate={calendarSelectedDate}
          setCalendarSelectedDate={setCalendarSelectedDate}
          calendarSelectedTime={calendarSelectedTime}
          setCalendarSelectedTime={setCalendarSelectedTime}
          availableAppointmentData={availableAppointmentData}
          getAvailableAppointments={getAvailableAppointments}
          setEarliestAppointmentDate={setEarliestAppointmentDate}
          appointmentInfo={appointmentInfo}
          setVehicleHasChanged={setVehicleHasChanged}
          earliestDateXTime={earliestDateXTime}
        />
      </FlexBox>
    </StyledBox>
  );
};

export default SelectDateTime;
