export const friendlyUrl = (param) => {
  return param
    ? param
      .replace(/[\u0300-\u036f]/g, '') // Remove illegal characters
      .replace(/\s+|\/+/g, '-') // Change whitespace, slashes to dashes
      .replace('&amp;', '') // remove &amp;
      .toLowerCase() // Change to lowercase
      .replace(/[^a-z0-9-]/g, '')
    : ''; // Remove anything that is not a letter, number or dash
};
