import React from 'react';
import { typography, colors, size } from '@argo/principles';
import styled from '@emotion/styled';
import { Paragraph } from '@argo/ui-elements';

const StyledParagraph = styled(Paragraph)`
  display: flex;
  ${typography.types.body};
  color: ${colors.primary.black};
  align-items:center;
  margin-bottom: ${size.xs}px;
`;

const StyledText = styled.span`
  color: ${colors.green.base};
`;

const OpenOrCloseStatus = ({ serviceCenterHours }) => {
  const convertedDateTime = new Date((new Date()).toLocaleString("en-US", { timeZone: serviceCenterHours[0]['linuxTimeZoneName']}));   
  const dayOfWeek = convertedDateTime.toLocaleDateString("en-US", { weekday: 'long'});

  const getTimePeriod = (timeString) => {
    const date = Date.parse(timeString) ? new Date(timeString) : new Date("1/1/2013 " + timeString);
    const timeInMintues = parseInt(date.getHours() * 60 + date.getMinutes());

    return  timeInMintues ;
  };
  
  const serviceCenterOpenHour = serviceCenterHours[0][`${dayOfWeek.toLowerCase()}OpenHour`];
  const serviceCenterCloseHour = serviceCenterHours[0][`${dayOfWeek.toLowerCase()}CloseHour`];

  const currentTime = getTimePeriod(convertedDateTime.toString());
  const openTime = serviceCenterOpenHour === 'Closed' ? 'Closed' : getTimePeriod(serviceCenterOpenHour);
  const closeTime = serviceCenterCloseHour === 'Closed' ? 'Closed' : getTimePeriod(serviceCenterCloseHour);

  const openStatus = serviceCenterOpenHour === 'Closed' && serviceCenterCloseHour === 'Closed' ? 'Closed' :
                      currentTime >= openTime &&
                      currentTime <= closeTime ? 'Open' : 'Closed';
  
  const openText = 'Service Department is '; 

  return (
    (openStatus === 'Open' ? (
      <StyledParagraph>
          {openText}
          &nbsp; 
          <StyledText>{openStatus}</StyledText>
          &nbsp;- closes {serviceCenterCloseHour}
      </StyledParagraph> 
      ) : (
      <StyledParagraph>
          {openText} {openStatus} 
      </StyledParagraph>
    ))
  )                               
};

export default OpenOrCloseStatus;
