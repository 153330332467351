import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import PromoEmailForm from './PromoEmailForm';
import { kbbLogo } from '../../constants/index';
import { Overlay } from '@argo/base-patterns';
import { breakpoints, colors, typography, size } from '@argo/principles';
import { Paragraph, Image, Heading } from '@argo/ui-elements';
import { useAdaptiveRender } from '@argo/utils';

const PromoContentContainer = styled.div`
  display: flex;
  margin-top: ${size.xs}px;
  justify-content: space-between;
`;

const PromoTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: ${size.sm}px;
`;

const StyledParagraph = styled(Paragraph)`
  margin-top: ${size.xs}px;
`;

const PromotionText = styled(Paragraph)`
  ${typography.types.XSSemi};
  color: ${colors.green.base};
  font-size: 18px;
  line-height: 21px;
  padding-right: ${size.md}px;
`;

const KbbText = styled.div`
  text-align: right;
  font-size: 19px;
  font-weight: 700;
  color: ${colors.primary.black};
`;

const PromoOverlay = ({ show, coupon, dealerInfo, onClose, shadowRoot, ddpDataLayer, ddpPixallDataLayer }) => {
  const [inputFormVariables, setInputFormVariables] = useState('');
  const [submitIsDisabled, setSubmitIsDisabled] = useState(true);
  const [formHasBeenSubmitted, setFormHasBeenSubmitted] = useState(false);

  const promoContainerRef = useRef();
  let width = 1080;
  if (typeof window !== 'undefined') {
    width = useAdaptiveRender().width;
  }
  const overlayWidth = () => {
    return width > breakpoints.md
      ? { widthRatio: '40' }
      : {
          widthRatio: Math.floor(((width - 31) / width) * 100),
          maxWidth: '400px'
        };
  };

  const updateInputFormVariables = (recipientEmail, senderName, senderEmail) => {
    setInputFormVariables({ recipientEmail, senderName, senderEmail });
  };

  return (
    <Overlay
      data-testid="promo-overlay-id"
      header={dealerInfo?.name}
      showOverlay={show}
      maxHeightRatio={85}
      onClose={onClose}
      container={shadowRoot}
      customHeaderContent={
        <Heading
          headingTag="h4"
          headingName={width > breakpoints.md ? 'SubsectionHeadingExtra' : 'SectionHeading'}
          css={{ marginTop: `${width < breakpoints.md ? size.xs + 'px' : size.sm + 'px'}` }}
        >
          {!formHasBeenSubmitted ? dealerInfo?.name : 'Thank You for Using Kelley Blue Book'}
        </Heading>
      }
      {...overlayWidth()}
    >
      {formHasBeenSubmitted && <p>Your email has been sent.</p>}
      {!formHasBeenSubmitted && (
        <>
          <PromoContentContainer ref={promoContainerRef}>
            <PromoTextContainer>
              <PromotionText>{coupon}</PromotionText>
              <StyledParagraph>
                To redeem this offer, simply send it to your email or share it with a friend.
              </StyledParagraph>
            </PromoTextContainer>
            <LogoContainer>
              <Image alt="kbb-logo" height="85" width="61" src={kbbLogo} />
              <KbbText>KBB.com</KbbText>
            </LogoContainer>
          </PromoContentContainer>
          <PromoEmailForm
            setSubmitIsDisabled={setSubmitIsDisabled}
            updateInputFormVariables={updateInputFormVariables}
            setFormHasBeenSubmitted={setFormHasBeenSubmitted}
            dealerInfo={dealerInfo}
            onClose={onClose}
            coupon={coupon}
            ddpDataLayer={ddpDataLayer}
            ddpPixallDataLayer={ddpPixallDataLayer}
          />
        </>
      )}
    </Overlay>
  );
};

export default PromoOverlay;
