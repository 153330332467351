import styled from '@emotion/styled';
import { FlexBox, colors } from '@argo/principles';
import { Heading, ProfileIcon, CarIcon, RepairIcon, DateTimeIcon, DealerIcon, CheckmarkIcon } from '@argo/ui-elements';
import { AppointmentConfirmationIconKeys } from '../../constants/appointmentConfirmation';

const StyledContainer = styled.div`
  padding: 4px 16px 4px 16px;
`;

const TextWrapper = styled.div`
  padding: 12px 0px;
`;

// Picks the desired icon inside button
export const getIcon = (icon) => {
  switch (icon) {
    case AppointmentConfirmationIconKeys.CAR_ICON:
      return <CarIcon size="23" data-testid={AppointmentConfirmationIconKeys.CAR_ICON}/>;
    case AppointmentConfirmationIconKeys.PROFILE_ICON:
      return <ProfileIcon size="23" data-testid={AppointmentConfirmationIconKeys.PROFILE_ICON}/>;
    case AppointmentConfirmationIconKeys.REPAIR_ICON:
      return <RepairIcon size="23" data-testid={AppointmentConfirmationIconKeys.REPAIR_ICON}/>;
    case AppointmentConfirmationIconKeys.DATE_TIME_ICON:
      return <DateTimeIcon size="23" data-testid={AppointmentConfirmationIconKeys.DATE_TIME_ICON}/>;
    case AppointmentConfirmationIconKeys.DEALER_ICON:
      return <DealerIcon size="23" data-testid={AppointmentConfirmationIconKeys.DEALER_ICON}/>;
    case AppointmentConfirmationIconKeys.CHECK_MARK_ICON:
      return <CheckmarkIcon size="23" data-testid={AppointmentConfirmationIconKeys.CHECK_MARK_ICON} color="green"/>
  }
};

export const getLabelText = (textsArray) => {
  return (
    <FlexBox flowColumn verticalSpacing="micro">
      {textsArray.map(text => <Heading headingName="ContentHeadingSemi" headingTag="h3" data-testid={'labelHeadingText'}>
        {text}
      </Heading>)}
    </FlexBox>
  )
};

export const IconLabelBox = ({
  iconCase,
  textsArray = []
}) => {
  return (
    <StyledContainer>
      <FlexBox alignItems="center" verticalSpacing={null}>
        {getIcon(iconCase)}
        <TextWrapper>{getLabelText(textsArray)}</TextWrapper>
      </FlexBox>
    </StyledContainer>
  );
};
