import serviceType from './serviceType';
import { normalizeName } from '../../inputUtils';

const name = 'Other/Not Sure';

const otherNotSure = {
  tab: serviceType.REPAIR.name,
  type: serviceType.REPAIR.type,
  repair: 'other---not-sure',
  name,
  displayName: name,
  description: name,
  normalizedName: normalizeName(name),
  text: name
};

export default otherNotSure;
