import React from 'react';
import ReactDom from 'react-dom/client';
import { CacheProvider } from '@emotion/react';
import { ServiceTabContainer } from '@kbbhydra/service-tab-container';
import createCache from '@emotion/cache';

const generateEmotionCache = (section) =>
  createCache({
    key: 'kbb-hydra-ddp',
    container: section,
    prepend: true
  });

class ServiceTabWebComponent extends HTMLElement {
  connectedCallback() {
    const section = document.createElement('section');
    const main = document.createElement('main');
    section.appendChild(main);
    this.attachShadow({ mode: 'open' }).appendChild(section);
    const root = ReactDom.createRoot(main);
    // grab svocid from params if exists
    const urlParams = new URLSearchParams(window.location.search);
    const svocidQueryString = urlParams.get('svocid');
    const svocid = svocidQueryString ? svocidQueryString : this.getAttribute('svocid');
    const sourceQueryString = urlParams.get('source');
    const source = sourceQueryString ? sourceQueryString : this.getAttribute('source');

    root.render(
      <CacheProvider value={generateEmotionCache(section)}>
        <ServiceTabContainer
          svocId={svocid}
          apiKey={this.getAttribute('apikey')}
          shadowRoot={this.shadowRoot}
          source={source}
        />
      </CacheProvider>
    );

    if (typeof CAIEventBus !== 'undefined') {
      const dealerDataFromMitt = CAIEventBus.on('dataFromMitt', (arg) => console.log(arg));
    }
    if (main.isConnected) {
      if (typeof CAIEventBus !== 'undefined') {
        CAIEventBus.emit('serviceTabConnected');
      }
    }
  }
}
customElements.define('service-tab-container', ServiceTabWebComponent);
