import React from 'react';
import { typography, colors, size, GridContainer } from '@argo/principles';
import styled from '@emotion/styled';
import { CheckmarkIcon, Paragraph } from '@argo/ui-elements';


const StyledHeader = styled.h4`
  ${typography.types.SExtra};
  color: ${colors.primary.black};
  text-align: left;
  margin-bottom: ${size.xs}px;
`;

const StyledGridContainer = styled(GridContainer)`
  align-items: center;
  justify-content: space-between;
  align-items: flex-start;
`;

const StyledDiv = styled.div`
  ${typography.types.body};
  color: ${colors.primary.black};
  display: flex;
  gap: ${size.xs}px;
  align-items: flex-start;
`;

const StyledSpan = styled.span`
  display: inline-flex;
  WebkitBoxAlign: center;
  align-items: center;
  margin-top: ${size.micro}px;
`;

const StyledParagraph = styled(Paragraph)`
  display: flex;
  ${typography.types.body};
  color: ${colors.primary.black};
  align-items:center;
`;

const ServiceCenterLanguages = ({ displayedDealer, breakpointKey = 'md' }) => {
  const { serviceCentersLangspoken } = displayedDealer;

  if (!serviceCentersLangspoken){
    return null;
  }
  
  return (
    <div data-cy="ServiceDepartmentLanguages">
      <StyledHeader>Additional Languages</StyledHeader>  
      <StyledGridContainer columnGap="md" gridTemplateColumns={{ [breakpointKey]: "1fr 1fr"}}>    
      {serviceCentersLangspoken.map((language, index) => (
        <StyledDiv key={index}>
          <StyledSpan><CheckmarkIcon color="black" size="16" /></StyledSpan>
          <StyledParagraph>{language}</StyledParagraph>
        </StyledDiv>
        ))
      }
      </StyledGridContainer> 
    </div>
  )
}; 

export default ServiceCenterLanguages;
