import React, { useEffect, useRef, useState } from 'react';
import { buildMappings, VehiclePickerForm } from '@argo/applied-patterns';
import { useStepByStepFormUnlocker } from '@argo/utils';
import { friendlyUrl } from '@kbbhydra/utils';
import { yearsQuery, makesQuery, modelsQuery, trims2Query, vehicleQuery } from '../tags/queries';
import { COMPONENT_STATE } from '@cuiConstants';
import StyledForm from './StyledForm';
import { GetPrepopulatedVehicleDataAsync } from './PrePopulate';

const VehiclePickerBase = ({
  client,
  handleVehicleInfoSelected,
  componentState = { state: COMPONENT_STATE.active },
  makes,
  prepopulateWith,
  showAllMakes = false,
  ...params
}) => {
  const [makeId, setMakeId] = useState();
  const [makeName, setMakeName] = useState();
  const manyMakes = useRef();

  const hasManyMakes = makes?.length > 1 ?? false;
  const showZipcode = params?.showZipcode ?? false;

  params.queries = {
    year: yearsQuery,
    make: hasManyMakes || showAllMakes ? makesQuery : false,
    model: modelsQuery,
    style: trims2Query
  };

  const mapping = buildMappings(params);

  const { formState, getNextField, getNextFieldKey, updateFormState, fieldOnChange, submitIsDisabled } =
    useStepByStepFormUnlocker({
      mapping
    });

  const formCss = StyledForm(hasManyMakes, showZipcode, showAllMakes);

  useEffect(() => {
    if (client) {
      if (!hasManyMakes && !showAllMakes) {
        client
          .query({
            query: makesQuery
          })
          .then((result) => {
            //query is to retrieve makeId
            var filteredMakes = result.data.list.filter((m) => m.text.toLowerCase() === makes[0].toLowerCase());
            if (filteredMakes && filteredMakes.length > 0) {
              setMakeId(filteredMakes[0].value);
              setMakeName(filteredMakes[0].text);
            }
          })
          .then(() => {
            //get list of years, update form state
            client
              .query({
                query: yearsQuery,
                variables: {}
              })
              .then((result) => {
                const yearsField = Object.assign({}, formState.year);
                yearsField.data = result.data.list;
                const newState = Object.assign({}, formState, { year: yearsField });
                updateFormState(newState);
              });
          })
          .catch((error) => {
            console.log('error in retrieving vehicle picker information');
          });
      } else {
        //if there are many makes, query for years and update form state with list of years
        client
          .query({
            query: yearsQuery,
            variables: {}
          })
          .then((result) => {
            const yearsField = Object.assign({}, formState.year);
            yearsField.data = result.data.list;
            const newState = Object.assign({}, formState, { year: yearsField });
            updateFormState(newState);
          })
          //query makes list and filter out for make ids that are only necessary, store in manyMakes ref
          .then(() => {
            client
              .query({
                query: makesQuery
              })
              .then((result) => {
                // if show all makes, makes dropwdown will be result of query
                if (showAllMakes) {
                  manyMakes.current = result.data.list;
                } else {
                  const filteredMakes = makes.flatMap((m) => {
                    let selectedMake = result.data.list.filter((l) => l.text.toLowerCase() === m.toLowerCase());
                    return selectedMake;
                  });
                  manyMakes.current = filteredMakes;
                }
              });
          })
          .catch((error) => {
            console.log('error in retrieving data for vehiclePicker');
          });
      }
    }
  }, []);

  /*************************************************************************************************** */
  // Populate vehiclePicker with preSelected vehicle data.

  const handlePrepopulate = async () => {
    const newState = Object.assign({}, formState);
    if (newState['year'].data) return;

    var data = await GetPrepopulatedVehicleDataAsync(
      prepopulateWith['year'],
      prepopulateWith['make'],
      prepopulateWith['model'],
      prepopulateWith['trim'],
      client
    );

    await updateField(newState['year'], data.YearsList, data.YearId);

    if (hasManyMakes && manyMakes.current && data.YearId)
      await updateField(newState['make'], manyMakes.current, data.MakeId);

    if (data.YearId && data.MakeId) await updateField(newState['model'], data.ModelsList, data.ModelId);

    if (data.YearId && data.MakeId && data.ModelId) await updateField(newState['style'], data.TrimsList, data.TrimId);

    updateFormState(newState);
  };

  const updateField = async (field, resultList, value) => {
    field.data = resultList;
    field.disabled = false;
    field.passedValidation = true;
    if (value) field.value = value;
  };

  if (
    prepopulateWith &&
    prepopulateWith.year &&
    prepopulateWith.make &&
    prepopulateWith.model &&
    prepopulateWith.trim
  ) {
    let promise = handlePrepopulate();
    promise.then(() => {}).catch((err) => console.log('##handlePrepopulate did throw: ' + err));
  }

  /*************************************************************************************************** */

  const onChangeCallBackInternal = async (e, { key, value, nextField, nextFieldKey }) => {
    const variables = Object.assign({}, formState[key].variables || {});
    switch (key) {
      case 'year':
        //if there is only one make in context, next field is to model
        if (!hasManyMakes && !showAllMakes) {
          nextField = Object.assign({}, formState.model);
          nextField.queryTag = modelsQuery;
          nextFieldKey = 'model';
          variables['yearId'] = value;
          variables['makeId'] = makeId;
        } else {
          nextField = Object.assign({}, formState.make);
          nextField.queryTag = makesQuery;
          nextFieldKey = 'make';
          variables['yearId'] = value;
        }
        break;

      case 'make':
        nextField = Object.assign({}, formState.model);
        nextField.queryTag = modelsQuery;
        nextFieldKey = 'model';
        variables['yearId'] = formState.year.value;
        variables['makeId'] = value;
        break;

      case 'model':
        nextField = Object.assign({}, formState.style);
        nextField.queryTag = trims2Query;
        nextFieldKey = 'style';
        variables['yearId'] = formState.year.value;
        variables['makeId'] = formState.make ? formState.make.value : makeId;
        variables['modelId'] = value;
        break;

      case 'trims':
        nextField = {};
        nextField.queryTag = null;
        break;

      case 'zipcode':
        const zipInput = e.target && e.target.value;
        const zipMask = zipInput.replace(/\D/g, '');
        e.target.value = zipMask;
        value = zipMask;
        break;
    }

    const newState = formState;

    if (nextField.queryTag) {
      const res = await client.query({
        query: nextField.queryTag,
        variables
      });

      let data = res.data.list;

      if (nextFieldKey === 'make') {
        const makesLower = manyMakes?.current?.map((m) => m.text?.toLowerCase());
        const validMakes = data.filter((m) => makesLower.includes(m.text?.toLowerCase()));
        data = validMakes?.length > 0 ? validMakes : manyMakes?.current || data;
      }

      newState[nextFieldKey] = Object.assign({}, nextField, {
        data,
        variables
      });
    }

    fieldOnChange({ value, index: formState[key].index }, Object.assign({}, formState, newState));
  };

  const collectVehiclePickerData = async () => {
    const vehiclePickerData = {
      YearId: formState.year.value,
      MakeId: formState.make ? formState.make.value : makeId,
      ModelId: formState.model.value,
      TrimId: formState.style.value,
      VehicleId: formState.style.value,
      TrimName: formState.style.text(),
      Mileage: formState.mileage ? formState.mileage.value : null,
      ZipCode: formState.zipcode ? formState.zipcode.value : null,
      ChromeStyleId: null
    };

    const filteredMake = formState?.make?.data
      ? formState.make.data.filter((m) => m.value === formState.make.value)[0]
      : null;
    if (filteredMake) vehiclePickerData.MakeName = filteredMake.text;
    else vehiclePickerData.MakeName = makeName;

    const filteredModel = formState?.model?.data
      ? formState.model.data.filter((m) => m.value === formState.model.value)[0]
      : null;
    if (filteredModel) vehiclePickerData.ModelName = filteredModel.text;

    return vehiclePickerData;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const vehiclePickerData = await collectVehiclePickerData();
    client
      .query({
        query: vehicleQuery,
        variables: {
          year: formState.year.value,
          make: vehiclePickerData.MakeName ? friendlyUrl(vehiclePickerData.MakeName) : '',
          model: vehiclePickerData.ModelName ? friendlyUrl(vehiclePickerData.ModelName) : ''
        }
      })
      .then((result) => {
        const { vehicle } = result.data;
        submitVehicleData(vehicle);
      });
  };

  const submitVehicleData = async (vehicleData) => {
    const vehiclePickerData = await collectVehiclePickerData();

    if (vehicleData?.trims) {
      const modelQueryFilteredTrims = vehicleData.trims.filter((t) => t.defaultVehicleId == formState.style.value);
      if (modelQueryFilteredTrims?.length == 1 && modelQueryFilteredTrims[0].chromeStyleIds) {
        vehiclePickerData.ChromeStyleId = modelQueryFilteredTrims[0].chromeStyleIds[0];
      }
      handleVehicleInfoSelected(vehiclePickerData);
    } else {
      handleVehicleInfoSelected(vehiclePickerData);
    }
  };

  return (
    componentState.state === COMPONENT_STATE.active && (
      <div>
        <VehiclePickerForm
          getNextField={getNextField}
          getNextFieldKey={getNextFieldKey}
          onChangeCallback={onChangeCallBackInternal}
          onSubmit={handleSubmit}
          formState={formState}
          submitText="Next"
          showZipcode={showZipcode}
          elementProps={{
            zipcode: {
              mask: '00000',
              maskPlaceholder: '',
              maskType: 'ZipCode',
              maxLength: 5
            }
          }}
          updateFormState={updateFormState}
          submitIsDisabled={submitIsDisabled}
          customFormCss={formCss}
          fieldOnChange={fieldOnChange}
        />
      </div>
    )
  );
};

export default VehiclePickerBase;
