import { ScheduleServiceEnabledDealers, isDevelopment } from '../ui/constants/index';

export const getFirstPayingDealer = (dealers, selectedMake) => {
  const payingDealer = dealers.find((dealer) => {
    if (selectedMake) {
      return (
        ScheduleServiceEnabledDealers.includes(dealer.provider.toLowerCase()) &&
        dealer.make.toLowerCase() === selectedMake.toLowerCase()
      );
    }
    return ScheduleServiceEnabledDealers.includes(dealer.provider.toLowerCase());
  });
  return payingDealer;
};

export const getPayedMakes = (dealers) => {
  const makes = dealers.flatMap((dealer) => {
    if (ScheduleServiceEnabledDealers.includes(dealer.provider.toLowerCase())) {
      return dealer.make;
    }
    return [];
  });
  return makes;
};

const getHasMaintenanceAtDoor = (dealers) => {
  let found = false;
  for (let i = 0; i < dealers.length; i++) {
    if (dealers[i]?.homeServices?.includes('VALET_SERVICES')) {
      found = true;
      break;
    }
  }
  return found;
};

export const getDealerAndProperties = (dealerInfo, selectedMake) => {
  const dealerAndProperties = {
    makes: [],
    isPaying: false,
    displayedDealer: null,
    hasMaintenanceAtDoor: false
  };

  if (dealerInfo?.items) {
    dealerAndProperties.makes = getPayedMakes(dealerInfo.items);
    dealerAndProperties.hasMaintenanceAtDoor = getHasMaintenanceAtDoor(dealerInfo.items);
    const payingDealerResult = getFirstPayingDealer(dealerInfo.items, selectedMake);
    if (payingDealerResult) {
      dealerAndProperties.displayedDealer = payingDealerResult;
      dealerAndProperties.isPaying = true;
    } else if (selectedMake) {
      const dealerResult = dealerInfo.items.find((dealer) => {
        return dealer.make.toLowerCase() === selectedMake.toLowerCase();
      });
      dealerAndProperties.displayedDealer = dealerResult;
      dealerAndProperties.isPaying = false;
    } else {
      // eslint-disable-next-line prefer-destructuring
      dealerAndProperties.displayedDealer = dealerInfo.items[0];
      dealerAndProperties.isPaying = false;
    }
  }
  if(dealerAndProperties.displayedDealer && isDevelopment){
    dealerAndProperties.displayedDealer.svocId = 'xts9010';
  }
  return dealerAndProperties;
};
