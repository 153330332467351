import setDdpPixAllProps from './setDdpPixAllProps';

const ddpPixallEvents = {
  getEvent: (event) => {
    return {
      ...event
    };
  },

  fireDdpPixallClick: (dataLayer, event) => {
    // eslint-disable-next-line no-unused-vars
    dataLayer.push({
      ...ddpPixallEvents.getEvent(event)
    });
  },

  clicks: {
    selectVehicleDdp: (vehicleInfo, svocId) => {
      const pixAllObj = setDdpPixAllProps(vehicleInfo);
      const { trimId, subCategory } = pixAllObj;
      return {
        type: 'event',
        eventType: 'click',     
        label: 'cta::select-vehicle',
        listingCode: svocId,
        cdl: {   
          eventProperties: {
            vehicle: {
              trimId: trimId,                   
              subCategory: subCategory || ''
            }
          }
        },
        ...pixAllObj        
      };
    },
    bookServiceAppointmentClickDdp: (vehicleInfo, svocId, provider) => {
      const pixAllObj = setDdpPixAllProps(vehicleInfo);
      const { subCategory } = pixAllObj;
      return {
        type: 'event',
        eventType: 'click',
        label: 'lead::service-appt',
        listingCode: svocId,
        cdl: {   
          eventProperties: {
            vehicle: {                  
              subCategory: subCategory || ''
            },
            service: {
              customerSource: provider
            },
            event: {
              valueEventCode: '56CB'
            }
          }
        },
        ...pixAllObj
      };
    },
    promoCardEmailShareDdp: (svocId, provider) => {
      const pixAllObj = setDdpPixAllProps();
      return {
        type: 'event',
        eventType: 'click',
        label: 'cta::dealer-promo-modal::email-share',
        listingCode: svocId,
        cdl: {   
          eventProperties: {
            service:{
              customerSource: provider
            },
            vehicle: {
              subCategory: pixAllObj?.subCategory || ''
            }
          }
        },
        ...pixAllObj
      }
    },
    phoneLinkClickDdp: (svocId, provider) => {
      const pixAllObj = setDdpPixAllProps();
      return {
        type: 'event',
        eventType: 'click',
        label: 'lead::phone',
        listingCode: svocId,
        cdl: {   
          eventProperties: {
            service:{
              customerSource: provider
            },
            vehicle: {
              subCategory: pixAllObj?.subCategory || ''
            },
            event: {
              valueEventCode: 'FFB3'
            }
          }
        },
        ...pixAllObj
      }
    },
    dealerEmailClickDdp: (svocId, provider) => {
      const pixAllObj = setDdpPixAllProps();
      return {
        type: 'event',
        eventType: 'click',
        label: 'cta::email::contact-us',
        listingCode: svocId,
        cdl: {   
          eventProperties: {
            service:{
              customerSource: provider
            }
          }
        },
        ...pixAllObj
      }
    },
    dealerLinkClickDdp: (svocId, provider) => {
      const pixAllObj = setDdpPixAllProps();
      return {
        type: 'event',
        eventType: 'click',
        label: `Click to Dealer Site - vw_wb_btn - ${pixAllObj.pageId}`,
        listingCode: svocId,
        cdl: {   
          eventProperties: {
            service:{
              customerSource: provider
            }
          }
        },
        ...pixAllObj
      }
    },
  }
};

export default ddpPixallEvents;