import React, { useState } from 'react';
import { css } from '@emotion/react';
import { breakpoints } from '@argo/principles';
import { FlexBox } from '@argo/principles';
import { Button } from '@argo/ui-elements';
import { COMPONENT_STATE } from '../../constants/componentState';
import { ToggleRadio } from '@argo/ui-elements';
import { useAdaptiveRender } from '@argo/utils';
import ServicesInfoList from '../ServicesInfoList/ServicesInfoList.json';
import styled from '@emotion/styled';

const StyledLeftColumn = styled.div`
  margin-bottom: -20px;
  @media (max-width: ${breakpoints.xl}px) {
    margin-bottom: 0px;
  }
`;

const ServicesBase = ({ componentState, handleServiceSelected }) => {
  const [servicesState, setServicesState] = useState(ServicesInfoList);
  const [clickedService, setClickedService] = useState({});
  const width = useAdaptiveRender().width;
  const isMobile = width < breakpoints.xl;

  const onNextClick = () => {
    handleServiceSelected(clickedService);
  };

  const onServiceClick = (e) => {
    e.stopPropagation();
    if (e.target.id) {
      const index = e.target.id;
      const serviceInfo = servicesState[index];
      setClickedService(() => ({
        ...clickedService,
        serviceName: serviceInfo.serviceName,
        xTimeServiceName: serviceInfo.xTimeServiceName,
        comment: serviceInfo.comment,
        index: index
      }));
    }
  };

  const servicesInfoListLength = ServicesInfoList.length;

  const isEven = servicesInfoListLength % 2 === 0 ? true : false;
  let midpoint = Math.floor(servicesInfoListLength / 2);

  if (isEven) midpoint = midpoint;
  else midpoint = midpoint + 1;

  return (
    componentState.state === COMPONENT_STATE.active && (
      <FlexBox default={['1 1 75%', '1 1 75%']} flexWrap={isMobile ? 'wrap' : 'nowrap'} verticalSpacing={'0'}>
        <StyledLeftColumn>
          {servicesState.slice(0, midpoint).map((service, index) => {
            return (
              <ToggleRadio
                key={'Toggle-' + index}
                onClick={onServiceClick}
                radioConfig={{
                  id: index,
                  name: service.serviceName,
                  checked: clickedService.serviceName === service.serviceName
                }}
                labelConfig={{
                  label: service.serviceName,
                  textAlgin: 'left'
                }}
                css={css`
                  margin-bottom: 20px;
                `}
              />
            );
          })}
        </StyledLeftColumn>
        <div>
          {servicesState.slice(midpoint).map((service, index) => {
            return (
              <ToggleRadio
                key={'Toggle-' + index + midpoint}
                onClick={onServiceClick}
                radioConfig={{
                  id: index + midpoint,
                  name: service.serviceName,
                  checked: clickedService.serviceName === service.serviceName
                }}
                labelConfig={{
                  label: service.serviceName,
                  textAlgin: 'left'
                }}
                css={css`
                  margin-bottom: 20px;
                `}
              />
            );
          })}
          <Button
            text="Next"
            buttonType="secondary"
            disabled={clickedService.serviceName ? false : true}
            inverse="false"
            sizeType="large"
            width="100%"
            onClick={onNextClick}
            data-testid="service-next"
          />
        </div>
      </FlexBox>
    )
  );
};

export default ServicesBase;
