import { css } from '@emotion/react';
import { colors, breakpoints, size } from '@argo/principles';
import { defaultFormStyles } from '@argo/applied-patterns';

const getGridSetting = (maxColumnStart, maxRowStart, minColumnStart, minRowStart) => {
  return {
    max: { columnStart: maxColumnStart, rowStart: maxRowStart },
    min: { columnStart: minColumnStart, rowStart: minRowStart }
  };
};

const withZipcode = {
  year: getGridSetting(1, 1, 1, 1),
  make: getGridSetting(1, 2, 1, 2),
  model: getGridSetting(1, 3, 1, 3),
  style: getGridSetting(2, 1, 1, 4),
  zipcode: getGridSetting(2, 2, 1, 5),
  button: getGridSetting(2, 3, 1, 6)
};

const withNoMake = {
  year: getGridSetting(1, 1, 1, 1),
  model: getGridSetting(1, 2, 1, 2),
  style: getGridSetting(2, 1, 1, 3),
  zipcode: getGridSetting(2, 2, 1, 4),
  button: getGridSetting(2, 3, 1, 5)
};

const withNoZipcode = {
  year: getGridSetting(1, 1, 1, 1),
  make: getGridSetting(1, 2, 1, 2),
  model: getGridSetting(2, 1, 1, 3),
  style: getGridSetting(2, 2, 1, 4),
  button: getGridSetting(2, 3, 1, 5)
};

const withNoMakeNoZipcode = {
  year: getGridSetting(1, 1, 1, 1),
  model: getGridSetting(1, 2, 1, 2),
  style: getGridSetting(2, 1, 1, 3),
  button: getGridSetting(2, 2, 1, 4)
};

const getCustomCss = (gridSettings, breakpoint) => {
  return Object.entries(gridSettings).map(([k, v]) => {
    const selector = k !== 'button' ? `.${k}` : k;
    const { max, min } = v || {};
    return css`
      ${selector} {
        @media (min-width: ${breakpoint}px) {
          grid-column-start: ${max?.columnStart};
          grid-row-start: ${max?.rowStart};
        }
        @media (max-width: ${breakpoint - 1}px) {
          grid-column-start: ${min?.columnStart};
          grid-row-start: ${min?.rowStart};
        }
      }
    `;
  });
};

const getCustomStyles = (hasManyMakes, showZipcode, breakpoint, showAllMakes) => {
  if (hasManyMakes && !showZipcode) return getCustomCss(withNoZipcode, breakpoint);
  if (!hasManyMakes && !showZipcode && !showAllMakes) return getCustomCss(withNoMakeNoZipcode, breakpoint);
  if (!hasManyMakes && showZipcode && !showAllMakes) return getCustomCss(withNoMake, breakpoint);
  return getCustomCss(withZipcode, breakpoint);
};

const getFormCss = (hasManyMakes, showZipcode, showAllMakes) => {
  const breakpoint = showZipcode ? breakpoints.lg : breakpoints.xl;
  const customStyles = getCustomStyles(hasManyMakes, showZipcode, breakpoint, showAllMakes);
  const mainCss = css`
    display: grid;
    margin: 0;
    @media (min-width: ${breakpoint}px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: ${breakpoint - 1}px) {
      grid-template-columns: repeat(1, 1fr);
    }
    column-gap: 20px;
    row-gap: 20px;
    flex: 1 1 auto;

    ${!showZipcode &&
    `button > span {
        background-color: ${colors.blue.darkBrightBlue};
        color: ${colors.primary.white};
      }`}
    ${showZipcode && `row-gap: ${size.xs}px;`}
  `;
  return [defaultFormStyles, mainCss, ...customStyles];
};

const StyledForm = (hasManyMakes, showZipcode, showAllMakes) => {
  return getFormCss(hasManyMakes, showZipcode, showAllMakes);
};

export default StyledForm;
