import { useState, useCallback } from 'react';

const useSelectDateTime = () => {
  const [appointmentInfo, setAppointmentInfo] = useState({
    startDate: '',
    startTime: '',
    providerInfo: ''
  });

  const handleSelectedDateTime = useCallback(
    (selectedAppointmentInfo) => {
      setAppointmentInfo(() => ({
        ...appointmentInfo,
        ...selectedAppointmentInfo
      }));

    }, [appointmentInfo]
  );

  const handleResetSelectedDateTime = () => {
    setAppointmentInfo({})
  }

  return ({appointmentInfo, handleSelectedDateTime, handleResetSelectedDateTime})
}

export default useSelectDateTime;
