const setDdpPixAllProps = (vehicleInfo) => {
  const dataLayer = (typeof window !== 'undefined' && window.coxAutoDataLayer) || {};
  let param = {
    pageType: 'dealer',
    eventSource: 'dealership',
    feature: 'Drive the 5'
  };

  const year = vehicleInfo?.YearId.toString();
  if (year) {
    param.year = year;
  }

  const make = vehicleInfo?.MakeName.toString();
  if (make) {
    param.make = make;
  }

  const model = vehicleInfo?.ModelName.toString();
  if (model) {
    param.model = model;
  }

  const bodyStyle = vehicleInfo?.TrimName.toString();
  if (bodyStyle) {
    param.bodyStyle = bodyStyle;
  }

  const getChromeStyleId = (chromeStyleIds) => {
    return chromeStyleIds && chromeStyleIds.length > 0 ? chromeStyleIds[0].toString() : undefined;
  }
  
  const chromeStyleId = vehicleInfo?.ChromeStyleId
          ? vehicleInfo?.ChromeStyleId.toString()
          : getChromeStyleId(vehicleInfo?.ChromeStyleIds ?? vehicleInfo?.chromeStyleIds)

  if(chromeStyleId){
     param.chromeStyleId = chromeStyleId;
  }

  param.trimId = vehicleInfo?.VehicleId;

  const urlParams = new URLSearchParams(window?.location?.search);
  param.zipCode = urlParams.get('zip');

  if(dataLayer[dataLayer.length - 1]?.user?.zipCode){
    param.searchFacetZipCode = dataLayer[dataLayer.length - 1].user.zipCode;
  }

  const hostName = window?.location?.host || '';
  const brandName = urlParams.get('brand') || '';
  if(hostName || brandName) {
    param.pageId = (hostName === 'www.kbb.com' || brandName === 'kbb' ? 'dlrinfoinvovrnew' : 'fyd_ddp');
  }

  return param;
};

export default setDdpPixAllProps;