import { gql } from '@apollo/client';

export default gql`
  query scheduleAppointment($scheduleAppointmentRequest: ScheduleAppointmentRequest) {
    scheduleAppointment(scheduleAppointmentRequest: $scheduleAppointmentRequest) {
      status
      message
      appointmentId
    }
  }
`;
