import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Heading, Paragraph } from '@argo/ui-elements';
import { colors } from '@argo/principles';
import { formatEarliestDate } from '@kbbhydra/schedule-service/src/ui/utils/dateTimeUtils';

const StyledParagraphAppointmentPreface = styled(Paragraph)`
  color: ${colors.primary.black};
`;

const StyledParagraphAppointmentDateAndTime = styled(Paragraph)`
  font-weight: bold;
  color: ${colors.primary.black};
`;

const EarliestAppointment = ({ earliestAppointmentDate }) => {
  const [formattedDate, setFormattedDate] = useState('Finding earliest appointment time...');

  useEffect(() => {
    if (earliestAppointmentDate) {
      if (earliestAppointmentDate === 'No available appointments') {
        setFormattedDate('No appointment found');
      } else {
        setFormattedDate(formatEarliestDate(new Date(earliestAppointmentDate)));
      }
    }
  }, [earliestAppointmentDate]);
  return (
    <>
      <Heading headingTag="h1" headingName="SubsectionHeadingExtra" enableBakeTitle={true}>
        Book an Appointment
      </Heading>
      <StyledParagraphAppointmentPreface>Appointment times as early as:</StyledParagraphAppointmentPreface>
      <StyledParagraphAppointmentDateAndTime className='applitoolsLayoutOnly' data-cy="earliestAppointment" style={{ fontWeight: 'bold', color: 'black' }}>
        {formattedDate}
      </StyledParagraphAppointmentDateAndTime>
    </>
  );
};

export default EarliestAppointment;
