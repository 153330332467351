const serviceType = {
  MAINTENANCE: {
    type: 'maintenance',
    name: 'maintenance-schedules',
    pageName: 'Maintenance Pricing',
    breadcrumbName: 'Maintenance Pricing',
    bvPrefix: 'maintenance-schedules'
  },
  REPAIR: {
    type: 'repair',
    name: 'auto-repair',
    pageName: 'Repair Pricing',
    breadcrumbName: 'Auto Repair Pricing',
    bvPrefix: 'auto-repair'
  }
};

export default serviceType;
