import routeService from '@kbbsdk/global-sdk/route-library/internal';

export const friendlyUrl = (param) => {
  return param
    ? param
      .replace(/[\u0300-\u036f]/g, '') // Remove illegal characters
      .replace(/\s+|\/+/g, '-') // Change whitespace, slashes to dashes
      .replace('&amp;', '') // remove &amp;
      .toLowerCase() // Change to lowercase
      .replace(/[^a-z0-9-]/g, '')
    : ''; // Remove anything that is not a letter, number or dash
};

export const getYmmtRouteFromVehicleInfo = ({ routeName, vehicleInfo, ...rest }) => {
  return routeService.getAppUrl(routeName, {
    make: friendlyUrl(vehicleInfo?.MakeName) || friendlyUrl(vehicleInfo?.makeName),
    model: friendlyUrl(vehicleInfo?.ModelName) || friendlyUrl(vehicleInfo?.modelName),
    year: vehicleInfo?.YearId || vehicleInfo?.yearId,
    vehicleid: (vehicleInfo?.VehicleId || vehicleInfo?.vehicleId) || null,
    ...rest
  });
};
