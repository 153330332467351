const toGtmString = (value) => (value ? value.toString() : undefined);

const ddpDataAnalytics = {
  getEvent: (event) => {
    return {
      ...event
    };
  },

  fireDdpClick: (dataLayer, event) => {
    dataLayer.push({
      ...ddpDataAnalytics.getEvent(event)
    });
  },
  
  defaultValues: {
    vehicle: {
      catalog: 'kbb'
    }
  },

  normalizedStringWithCharLimit: (string = '', charLimit = 25) => {
    const parsedPromo = string
      .replace(/[.,/#!$%^&*;:{}=\-_`~"()]/g, ' ')
      .split(' ')
      .filter((ele) => ele !== '');

    const normalizedArray = [];
    let charCount = 0;
    parsedPromo.forEach((ele) => {
      const lowerCaseEle = ele.toLowerCase();
      if (charCount >= charLimit) {
        return;
      }
      if (charCount < charLimit) {
        normalizedArray.push(lowerCaseEle.slice(0, charLimit - charCount));
      }
      charCount += ele.length;
    });
    return normalizedArray.join('-');
  },

  mapVehicleInfo: (vehicleInfo) => {
    return {
      year: toGtmString(vehicleInfo?.YearId),
      makeId: toGtmString(vehicleInfo?.MakeId),
      makeName: toGtmString(vehicleInfo?.MakeName),
      modelId: toGtmString(vehicleInfo?.ModelId),
      modelName: toGtmString(vehicleInfo?.ModelName),
      trimName: toGtmString(vehicleInfo?.TrimName),
      vehicleId: toGtmString(vehicleInfo?.VehicleId),
      vin: toGtmString(vehicleInfo?.Vin),
      chromeStyleId:
        vehicleInfo?.ChromeStyleIds && vehicleInfo?.ChromeStyleIds.length > 0
          ? toGtmString(vehicleInfo.ChromeStyleIds[0])
          : undefined
    };
  },

  constructVehicle: (vehicleInfo = null, svocId = null, provider = null, subProvider = null, type = null, defaultValues = false) => {
    const defaults = defaultValues ? ddpDataAnalytics.defaultValues.vehicle : {};
    const vehicle = vehicleInfo ? ddpDataAnalytics.mapVehicleInfo(vehicleInfo) : {};
    return {
      ...defaults,
      ...vehicle,
      dealershipId: toGtmString(svocId),
      customerType: provider && provider.toLowerCase(),
      priorityType: type
    };
  },

  clicks: {
    phoneLinkClick: (svocId, provider, type) => {
      return {
        event: 'analytics_event',
        eventElement: 'service',
        eventAction: 'submit',
        eventResult: 'lead::phone',
        eventNonInteractive: false,
        eventData: {
          vehicle: ddpDataAnalytics.constructVehicle(null, svocId, provider, null, type)
        }
      };
    },
    promoTextClick: (promo, svocId, provider, type) => {
      return {
        event: 'analytics_event',
        eventElement: 'service-and-repair',
        eventAction: 'click',
        eventResult: `promo-text-link::${ddpDataAnalytics.normalizedStringWithCharLimit(promo, 25)}`,
        eventNonInteractive: false,
        eventData: {
          vehicle: ddpDataAnalytics.constructVehicle(null, svocId, provider, null, type)
        }
      };
    },
    sendPromoEmailClick: (svocId, provider, type) => {
      return {
        event: 'analytics_event',
        eventElement: 'service-and-repair',
        eventAction: 'submit',
        eventResult: 'cta::dealer-promo-modal::email-share',
        eventNonInteractive: true,
        eventData: {
          vehicle: ddpDataAnalytics.constructVehicle(null, svocId, provider, null, type)
        }
      };
    },
    dealerServiceSchedulingLinkClick: (svocId, provider, type) => {
      return {
        event: 'analytics_event',
        eventElement: 'service',
        eventAction: 'submit',
        eventResult: 'lead::phone::dlr-lnk',
        eventNonInteractive: true,
        eventData: {
          vehicle: ddpDataAnalytics.constructVehicle(null, svocId, provider, null, type)
        }
      };
    },
    sendDealerEmailClick: (vehicle, svocId, provider, subProvider, type) => {
      return {
        event: 'analytics_event',
        eventElement: 'dealer',
        eventAction: 'submit',
        eventResult: 'cta::email::contact-us',
        eventNonInteractive: false,
        eventData: {
          vehicle: ddpDataAnalytics.constructVehicle(vehicle, svocId, provider, subProvider, type)
        }
      };
    },
    promoUnitContentMod: (unitIndex) => {
      return {
        event: 'analytics_event',
        eventElement: 'service',
        eventAction: 'submit',
        eventResult: `content_mod_${unitIndex}`,
        eventNonInteractive: false,
      };
    },
    promoUnitContentModListScroll: (direction) => {
      return {
        event: 'analytics_event',
        eventElement: 'service',
        eventAction: 'submit',
        eventResult: `content_mod::list_${direction}`,
        eventNonInteractive: false,
      };
    },
    promoUnitContentModDotIndicator: (index) => {
      return {
        event: 'analytics_event',
        eventElement: 'service',
        eventAction: 'submit',
        eventResult: `content_mod::list_${index}`,
        eventNonInteractive: false,
      };
    }
  }
};

export default ddpDataAnalytics;
