/** @jsx jsx */

import { jsx } from '@emotion/react';
import React, { Fragment, useEffect } from 'react';
// import { Click } from 'constants/analytics';
import { FlexBox } from '@argo/principles';
import { ToggleRadio } from '@argo/ui-elements';

const morningTimeSlots = [
  { text: '09:00 am', hour: 9, minute: 0 },
  { text: '09:30 am', hour: 9, minute: 30 },
  { text: '10:00 am', hour: 10, minute: 0 },
  { text: '10:30 am', hour: 10, minute: 30 },
  { text: '11:00 am', hour: 11, minute: 0 },
  { text: '11:30 am', hour: 11, minute: 30 }
];

const afternoonTimeSlots = [
  { text: '12:00 pm', hour: 12, minute: 0 },
  { text: '12:30 pm', hour: 12, minute: 30 },
  { text: '01:00 pm', hour: 13, minute: 0 },
  { text: '01:30 pm', hour: 13, minute: 30 },
  { text: '02:00 pm', hour: 14, minute: 0 },
  { text: '02:30 pm', hour: 14, minute: 30 },
  { text: '03:00 pm', hour: 15, minute: 0 },
  { text: '03:30 pm', hour: 15, minute: 30 }
];

const CrmCalendarTime = ({
  getNormalizedTime,
  selectedCalendarTime,
  activeTabIndex,
  shortProvider,
  shortSubProvider,
  calendarSelectedTime,
  calendarSelectedDate,
  setDateHasChanged
}) => {
  const timeSlotSpacing = 'xs';
  const allTimeSlots = activeTabIndex === 0 ? morningTimeSlots : afternoonTimeSlots; // 0 is morning, 1 is afternoon tab
  const event = 'event102';

  useEffect(() => {
    if (!calendarSelectedDate) {
      selectedCalendarTime(morningTimeSlots[0]);
    }
  }, [calendarSelectedDate]);

  return (
    <Fragment>
      <FlexBox
        alignItems="left"
        default={['0 0 20', '0 0 20', '0 0 20', '0 0 20', '0 0 20']}
        horizontalSpacing={timeSlotSpacing}
        justifyContent="flex-start"
        verticalSpacing={timeSlotSpacing}
        flexWrap="wrap"
      >
        <FlexBox
          alignItems="left"
          default={['0 0 20', '0 0 20', '0 0 20', '0 0 20', '0 0 20']}
          horizontalSpacing={timeSlotSpacing}
          justifyContent="flex-start"
          verticalSpacing={timeSlotSpacing}
          flexWrap="wrap"
        >
          {allTimeSlots.map((time, index) => {
            const normalizedTime = getNormalizedTime ? getNormalizedTime(time) : time;
            return (
              <div
                key={index}
                data-testid="time_select_btn"
              >
                <ToggleRadio
                  data-testid="SelectedTime-Radio"
                  isShowRadio={false}
                  labelConfig={{
                    label: time.text,
                    textAlign: 'left'
                  }}
                  onClick={() => {
                    selectedCalendarTime(time);
                  }}
                  radioConfig={{
                    disabled: false,
                    id: new Date(time),
                    name: 'radio-time',
                    checked: Number(new Date(calendarSelectedTime)) === Number(new Date(normalizedTime)),
                    padding: '8px'
                  }}
                  style={{
                    marginLeft: '1px'
                  }}
                />
              </div>
            );
          })}
        </FlexBox>
      </FlexBox>
    </Fragment>
  );
};

export default CrmCalendarTime;
