/* eslint-disable radix */
/* eslint-disable prefer-template */
/* eslint-disable prefer-destructuring */
export function CustomDate(timeString) {
  function z(n) {
    return n < 10 ? '0' + n : n;
  }
  function y(n) {
    return n <= 12 ? n : n - 12;
  }
  const completeDate = timeString.split('T')[0];
  const timeAndOffset = timeString.split('T')[1];
  // date
  this.year = completeDate.split('-')[0];
  this.month = completeDate.split('-')[1];
  this.day = completeDate.split('-')[2];

  this.date = this.year + '/' + this.month + '/' + this.day;

  // negative time offset
  if (timeAndOffset.search('-') != -1) {
    const completeOffset = timeAndOffset.split('-')[1];
    this.offset = parseInt(completeOffset.split(':')[0]) * -1;

    const originalTime = timeAndOffset.split('-')[0];
    this.hours = parseInt(originalTime.split(':')[0]);
    this.minutes = parseInt(originalTime.split(':')[1]);
    const ap = this.hours < 12 ? 'am' : 'pm';

    this.time = this.hours + ':' + this.minutes;
    this.displayTime = z(y(this.hours)) + ':' + z(this.minutes) + ' ' + ap;
  }
  // positive time offset
  // eslint-disable-next-line eqeqeq
  else if (timeAndOffset.search(/\+/) != -1) {
    const completeOffset = timeAndOffset.split('+')[1];
    this.offset = parseInt(completeOffset.split(':')[0]);

    const originalTime = timeAndOffset.split('+')[0];
    this.hours = parseInt(originalTime.split(':')[0]);
    this.minutes = parseInt(originalTime.split(':')[1]);
    const ap = this.hours < 12 ? 'am' : 'pm';

    this.time = this.hours + ':' + this.minutes;
    this.displayTime = z(y(this.hours)) + ':' + z(this.minutes) + ' ' + ap;
  }
  // no time offset declared
  else {
    this.hours = parseInt(timeAndOffset.split(':')[0]);
    this.minutes = parseInt(timeAndOffset.split(':')[1]);
    const ap = this.hours < 12 ? 'am' : 'pm';
    this.offset = 0;
    this.time = this.hours + ':' + this.minutes;
    this.displayTime = z(y(this.hours)) + ':' + z(this.minutes) + ' ' + ap;
  }

  this.offsetDate = new Date(this.date + ' ' + this.time);
}

export const formatDate = (selectedDate) => {
  const month = selectedDate.toLocaleString('en-us', { month: 'long' });
  const day = selectedDate.getDate();
  const year = selectedDate.getFullYear();

  const formattedDate = `${month} ${day}, ${year}`;
  return formattedDate;
};

export const formatEarliestDate = (selectedDate) => {
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const month = selectedDate.toLocaleString('en-us', { month: 'short' });
  const day = selectedDate.getDate();
  const year = selectedDate.getFullYear();
  const weekday = daysOfWeek[selectedDate.getDay()];

  const formattedDate = `${weekday} ${month.toUpperCase()} ${day}, ${year}`;
  return formattedDate;
};

export const formatTime = (d) => {
  const date = new CustomDate(d);
  return date.displayTime;
};

export const nextApptDayOffsets = [
  2, // Sun => Tues
  1, // Mon => Tues
  0, // Tues => Tues
  0, // Wed => Wed
  0, // Thur => Thur
  4, // Fri => Tues
  3 // Sat => Tues
];

export const defaultDaysOutOffset = 7;

export const checkFederalHoliday = (selectedDate) => {
  // Since Fri, Sat, Sun, and Mon are blackout dates, we only need to check holidays fixed to a specific date
  // like Christmas, Dec 25, and not worry about holidays that fall on a black out day such as labor day, memorial day, etc.
  // New Years.
  // The date input here is a a non-blackout date - ie, Tues, Wed, or Thur
  const month = selectedDate.getMonth();
  const date = selectedDate.getDate();
  const day = selectedDate.getDay();

  // New year
  if (month === 0 && date === 1) {
    return true;
  }
  // July 4
  if (month === 6 && date === 4) {
    return true;
  }
  // Veteran's day
  if (month === 10 && date === 11) {
    return true;
  }
  // Thanksgiving day
  if (month === 10) {
    const firstDay = new Date(selectedDate.getFullYear(), month, 1);
    const dayOfWeek = firstDay.getDay();
    const thanksGiving = 22 + ((11 - dayOfWeek) % 7);
    if (date === thanksGiving) {
      return true;
    }
    return false;
  }

  // Christmas day
  if (month === 11 && date === 24) {
    return true;
  }
};

export const addDaysOutOffset = (selectedDate, daysOutOffset) => {
  // This function ensures the next available appointment is at least ${daysOutOffset} days from today
  const presentDate = new Date(Date.now());
  const timeDifference = selectedDate.getTime() - presentDate.getTime();
  const dayDifference = timeDifference / (1000 * 3600 * 24);

  if (dayDifference >= daysOutOffset) {
    // since selected date is ${daysOutOffset} ahead of today, no need to apply offset logic
    return 0;
  } else {
    // removing the difference between today and selected date from the ${daysOutOffset}
    // ${daysOutOffset} is adjusted from today not date selected
    return daysOutOffset - dayDifference;
  }
};

export const getFirstApptDate = ({
  selectedDate,
  offsetTable = nextApptDayOffsets,
  daysOutOffset = defaultDaysOutOffset,
  startTime
}) => {
  const minimumOffsetDays = addDaysOutOffset(selectedDate, daysOutOffset);
  let dayIndex = selectedDate.getDay();
  selectedDate.setDate(selectedDate.getDate() + minimumOffsetDays + offsetTable[dayIndex]);
  // If applicable federal holiday, then select next valid date
  const isFederalHoliday = checkFederalHoliday(selectedDate);
  if (isFederalHoliday) {
    // Get next valid date after the holiday
    dayIndex = selectedDate.getDay() + 1;
    selectedDate.setDate(selectedDate.getDate() + 1 + minimumOffsetDays + offsetTable[dayIndex]);
  }

  if (startTime?.hour) {
    selectedDate.setHours(startTime.hour);
  }
  if (startTime?.minute) {
    selectedDate.setMinutes(startTime.minute);
  }
  return selectedDate;
};

export const getFirstAvailableApptDateFromNow = ({ daysOutOffset = defaultDaysOutOffset }) => {
  // Determine first valid appointment date
  const firstAptDate = getFirstApptDate({ selectedDate: new Date(Date.now()), daysOutOffset });
  // Fix day offsets
  const fixedFirstAptDate = getFirstApptDate({ selectedDate: firstAptDate, daysOutOffset });
  return fixedFirstAptDate;
};

export const getCrmDaysOutOffset = (dealerInfo) => {
  const serviceDepartment = dealerInfo?.serviceCentersDepartments?.find((d) => d.departmentName?.toLowerCase() === 'service');
  const isAsap = serviceDepartment?.firstAvailableAppointmentOptionName?.toLowerCase() === 'asap';
  const numDaysOut = serviceDepartment?.numberOfDaysOut > 0 ? serviceDepartment?.numberOfDaysOut : undefined;
  return isAsap ? 1 : numDaysOut;
};
