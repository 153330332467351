import { WEBAPI_KEY, WEBAPI_URL, isLocalHost} from '../ui/constants/index';
import axios from 'axios';

export const fetchDealer = async (svocId, apiKey) => {
  const headers = isLocalHost ? {
    'x-api-key': apiKey,
    'localhost' : '*'
  } : {
    'x-api-key': apiKey
  }
  try {
    const result = await axios.get(`${WEBAPI_URL}/api/service-dealer?svocid=${svocId}`, {
      headers
    })
    if (result.data) return result.data;
    return null;
  } catch (err) {
    return null;
  }
};
