import React from 'react';
import { typography, colors, FlexBox, size } from '@argo/principles';
import { Box, Spinner } from '@argo/ui-elements';
import styled from '@emotion/styled';
import { IconLabelBox } from '../shared/IconLabelBox/index';
// import { IconLabelBox } from '@kbbhydra/common-ui/src/IconLabelBox/index.js'
import { formatDate, formatTime } from '../utils/dateTimeUtils';

const StyledBox = styled(Box)`
  min-height: 100%;
`;

const StyledFlexBox = styled(FlexBox)`
  margin-top: 20px;
`;

const ContactInformationTextParagraph = styled.p`
  ${typography.types.MSemi};
  color: ${colors.primary.black};
  margin-bottom: ${size.xs12}px;
`;

const StyledIconLabelBox = styled.div`
  padding: 4px 16px 4px 16px;
`;

const AppointmentLoader = ({ vehicleInfo, contactInfo, selectedService, calendarSelectedTime, displayedDealer }) => {
  const { YearId, MakeName, ModelName } = vehicleInfo;
  const { firstName, lastName, email, phone } = contactInfo;
  const { serviceName } = selectedService;
  const { name } = displayedDealer;
  const selectedDateTimeInfo = {
    startDate: new Date(calendarSelectedTime),
    startTime: calendarSelectedTime
  };
  const readableFriendlyDate = formatDate(selectedDateTimeInfo?.startDate);
  const readableFriendlyTime = formatTime(selectedDateTimeInfo?.startTime);

  //TODO: When Refactoring IconLabelBox to import it from CommonUI package, make sure to add the StyledIconLabelBox defined to add the padding needed.
  return (
    <StyledBox backgroundColor="white" response={false} shadow={false} width={'100%'}>
      <ContactInformationTextParagraph>Setting up your Appointment</ContactInformationTextParagraph>
      <Box backgroundColor="offwhite" shadow={false}>
        <IconLabelBox iconCase={'checkMarkIcon'} textsArray={['Contacting Service Center']} />
        <IconLabelBox iconCase={'checkMarkIcon'} textsArray={['Sending your Service Information']} />
      </Box>
      <FlexBox
        default={['0 0 50%', '0 0 50%', '0 0 50%', '0 0 50%', '0 0 50%']}
        sm={['0 0 100%', '0 0 100%', '0 0 100%', '0 0 100%', '0 0 100%']}
        md={['0 0 100%', '0 0 100%', '0 0 100%', '0 0 100%', '0 0 100%']}
        flexWrap="wrap"
        verticalSpacing={'xs'}
      >
        <IconLabelBox
          className="applitoolsLayoutOnly"
          iconCase={'dateTimeIcon'}
          textsArray={[`${readableFriendlyDate} @ ${readableFriendlyTime}`]}
        />
        <IconLabelBox iconCase={'dealerIcon'} textsArray={[`${name}`]} />
        <IconLabelBox iconCase={'carIcon'} textsArray={[`${YearId} ${MakeName} ${ModelName}`]} />
        <IconLabelBox iconCase={'repairIcon'} textsArray={[serviceName]} />
        <IconLabelBox iconCase={'profileIcon'} textsArray={[`${firstName} ${lastName}`, `${phone}`, `${email}`]} />
      </FlexBox>
      <StyledFlexBox className="applitoolsLayoutOnly" justifyContent="center">
        <Spinner data-testid={'appointmentSpinner'} />
      </StyledFlexBox>
    </StyledBox>
  );
};

export default AppointmentLoader;
