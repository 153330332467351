import { useState, useCallback } from 'react';

const useSelectVehicle = () => {
  const [vehicleInfo, setVehicleInfo] = useState({
    YearId: '',
    MakeId: '',
    MakeName: '',
    ModelId: '',
    ModelName: '',
    TrimId: '',
    VehicleId: '',
    ChromeStyleId: ''
  });

  // Invoked after user has selected all vehicle information from dropdowns
  // and clicks CTA to update vehicleInfo state with values from passed in argument.
  // useCallback memoizes this function so that as a prop it will not change unless vehicleInfo changes.
  // Note that we do not directly mutate the state since that would not cause the necessary rerender due to referential equality.

const handleSelectedVehicleInfo = useCallback(
    (selectedVehicleInfo) => {
      setVehicleInfo(() => ({
        ...vehicleInfo,
        ...selectedVehicleInfo
      }));
    }, [vehicleInfo]
  );

  return ({ vehicleInfo, handleSelectedVehicleInfo });
};

export default useSelectVehicle;
