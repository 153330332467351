import React from 'react';
import { Button } from '@argo/ui-elements';
import { breakpoints, size } from '@argo/principles';
import styled from '@emotion/styled';
import ddpDataAnalytics from '../../utils/ddpDataAnalytics';
import ddpPixallEvents from '../../utils/pixAll/ddpPixAllEvents';

const StyledButton = styled(Button)`
width: 422px;
@media (max-width: ${breakpoints.xl}px) {
    width: 360px;
  }
  @media (max-width: ${breakpoints.lg}px) {
    width: 100%;
    margin-top: ${size.xs}px;
  }
`;

const ContactServiceButton = ({dealerInfo, buttonText, ddpDataLayer, ddpPixallDataLayer, displayedDealer}) => {
  const { provider, subProvider, svocId, type } = displayedDealer;

  const handleContactServiceClick = () => {
    ddpDataAnalytics.fireDdpClick(ddpDataLayer, ddpDataAnalytics.clicks.sendDealerEmailClick(null, svocId, provider, subProvider, type ));
    ddpPixallEvents.fireDdpPixallClick(ddpPixallDataLayer, ddpPixallEvents.clicks.dealerEmailClickDdp(svocId, provider));

    if (typeof CAIEventBus !== 'undefined') {
      CAIEventBus.emit('contactServiceEvent', { message: 'contact service event', dealerInfo });
    }
  }
  return (
    <StyledButton
    data-cy="contactServiceButton"
      buttonType='secondary'
      horizontalPadding="0"
      inverse='true'
      text={buttonText}
      minimizeSize={false}
      disabled={false}
      href='#'
      target='_self'
      rel='nofollow'
      isRemovePadding='false'
      enableCapitalize={true}
      sizeType='regular' width='auto'
      iconPosition='left'
      onClick={handleContactServiceClick}
      />
  );
};

export default ContactServiceButton;
