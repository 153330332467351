import React from 'react';
import { typography, colors, FlexBox, size } from '@argo/principles';
import { Button, Box } from '@argo/ui-elements';
import styled from '@emotion/styled';
import { IconLabelBox } from '../shared/IconLabelBox/index';
// import { IconLabelBox } from '@kbbhydra/common-ui/src/IconLabelBox/index.js'
import { formatDate, formatTime } from '../utils/dateTimeUtils';

const StyledBox = styled(Box)`
  min-height: 100%;
`;

const ContactInformationTextParagraph = styled.p`
  ${typography.types.MSemi};
  color: ${colors.primary.black};
  margin-bottom: ${size.xs12}px;
`;

const StyledButton = styled(Button)`
  margin-top: 40px;
  margin-bottom: 20px;
  max-height: 20px;
`;

const StyledIconLabelBox = styled.div`
  padding: 4px 16px 4px 16px;
`;

const downloadIcsFile = (value, name) => {
  const element = document.createElement('a');
  const file = new Blob([value], { type: 'text/calender' });
  // const file = new Blob([value], { type: 'text/calendar;charset=utf-8' }); //added for ie
  element.href = URL.createObjectURL(file);
  element.download = `${name}.ics`;
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
};

const AppointmentConfirmation = ({
  vehicleInfo,
  contactInfo,
  selectedService,
  calendarSelectedTime,
  displayedDealer
}) => {
  const { YearId, MakeName, ModelName } = vehicleInfo;
  const { firstName, lastName, email, phone } = contactInfo;
  const { serviceName } = selectedService;
  const { name, address, city, state, zip, latitude, longitude } = displayedDealer;
  const selectedDateTimeInfo = {
    startDate: new Date(calendarSelectedTime),
    startTime: calendarSelectedTime
  };
  const readableFriendlyDate = formatDate(selectedDateTimeInfo?.startDate);
  const readableFriendlyTime = formatTime(selectedDateTimeInfo?.startTime);

  const CreateCalendarEvent = () => {
    const event = {
      start: [
        new Date(calendarSelectedTime).getFullYear(),
        new Date(calendarSelectedTime).getMonth() + 1,
        new Date(calendarSelectedTime).getDate(),
        new Date(calendarSelectedTime).getHours(),
        new Date(calendarSelectedTime).getMinutes()
      ],
      duration: { hours: 2, minutes: 0 },
      title: `Scheduled Appointment Confirmation @ ${name}`,
      description: `You have a scheduled ${
        serviceName || 'maintenance & repair'
      } service with ${name} on ${readableFriendlyDate} at ${readableFriendlyTime}`,
      location: `${address} ${city}, ${state} ${zip}`,
      geo: { lat: latitude, lon: longitude },
      categories: ['Appointment', `${city}`, `${name}`],
      status: 'CONFIRMED',
      busyStatus: 'BUSY',
      organizer: { name: `${name}`, email: 'donotreplay@kbb.com' },
      attendees: [
        {
          name: `${firstName} ${lastName}`,
          email: `${email}`,
          rsvp: true,
          partstat: 'ACCEPTED',
          role: 'REQ-PARTICIPANT'
        }
      ]
    };
    const fileName = `Appointment_Scheduled_at_${name.replace(' ', '_')}`;
    const ics = require('ics');
    ics.createEvent(event, (error, value) => {
      if (error) {
        console.log(error);
        return;
      }
      downloadIcsFile(value, fileName);
    });
  };

  //TODO: When Refactoring IconLabelBox to import it from CommonUI package, make sure to add the StyledIconLabelBox defined to add the padding needed.
  return (
    <StyledBox backgroundColor="white" response={false} shadow={false} width={'100%'}>
      <ContactInformationTextParagraph>Appointment Confirmation</ContactInformationTextParagraph>
      <FlexBox
        default={['0 0 50%', '0 0 50%', '0 0 50%', '0 0 50%', '0 0 50%']}
        sm={['0 0 100%', '0 0 100%', '0 0 100%', '0 0 100%', '0 0 100%']}
        md={['0 0 100%', '0 0 100%', '0 0 100%', '0 0 100%', '0 0 100%']}
        flexWrap="wrap"
        verticalSpacing={'xs'}
      >
        <IconLabelBox
          iconCase={'dateTimeIcon'}
          className="applitoolsLayoutOnly"
          textsArray={[`${readableFriendlyDate} @ ${readableFriendlyTime}`]}
        />
        <IconLabelBox iconCase={'dealerIcon'} textsArray={[`${name}`]} />
        <IconLabelBox iconCase={'carIcon'} textsArray={[`${YearId} ${MakeName} ${ModelName}`]} />
        <IconLabelBox iconCase={'repairIcon'} textsArray={[serviceName]} />
        <IconLabelBox iconCase={'profileIcon'} textsArray={[`${firstName} ${lastName}`, `${phone}`, `${email}`]} />
        <StyledButton
          text="Send to Calender"
          buttonType="secondary"
          disabled={false}
          inverse="false"
          sizeType="large"
          onClick={CreateCalendarEvent}
        />
      </FlexBox>
    </StyledBox>
  );
};

export default AppointmentConfirmation;
