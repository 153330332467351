import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { size, breakpoints, FlexBox } from '@argo/principles';
import { useAdaptiveRender } from '@argo/utils';
import { ScheduleService } from '@kbbhydra/schedule-service';
import { fetchDealer } from '../../api/fetchDealer';
import DealerOffers from '../DealerOffers';
import DealerHomeServices from '../DealerHomeServices';
import ContactServiceButton from '../ContactServiceButton';
import PromoModule from '../PromoModule';
import EarliestAppointment from '../EarliestAppointment/';
import PhoneCallButton from '../PhoneCallButton';
import DealerWebsiteLink from '../DealerWebsiteLink';
import { getDealerAndProperties } from '../../utils/ddpFunctions';
import ErrorDealerInfoUnavailable from './ErrorDealerInfoUnavailable';
import { getDataLayerCommonData } from '../../utils/windowDataAnalytics';
import { getWinDataLayer, getWinPixallDataLayer } from '@kbbhydra/utils';
import ServiceCenterFeatures from '../ServiceCenterFeatures';
import AboutServiceCenter from '../AboutServiceCenter';
import ServiceCenterHoursOfOperation from '../ServiceCenterHoursOfOperation';
import ServiceCenterServicesAndAmenities from '../ServiceCenterServicesAndAmenities';
import ServiceCenterLanguages from '../ServiceCenterLanguages';

const EarliestAppointmentContainer = styled.div`
  margin-bottom: ${size.sm}px;
`;

const ScheduledServiceContainer = styled.div`
  width: 100%;
`;

const PhoneCallButtonContainer = styled.div`
  margin-top: ${(props) => (props.hasOffers ? `${size.sm}px` : `${size.xs}px`)};
  @media (max-width: ${breakpoints.lg}px) {
    margin-top: ${size.sm}px !important;
    width: 100%;
  }
`;

const DealerOffersContainer = styled.div`
  @media (max-width: ${breakpoints.lg}px) {
    margin-top: ${size.sm}px !important;
    width: 100%;
  }
`;

const ContactServiceButtonContainer = styled.div`
  margin-top: ${size.sm}px;
  @media (max-width: ${breakpoints.lg}px) {
    width: 100%;
  }
`;

const DealerWebsiteLinkContainer = styled.div`
  margin-top: ${size.sm}px;
`;

const DealerHomeServicesContainer = styled.div`
  margin-top: ${size.xxxl}px;
`;

const PromoModuleContainer = styled.div`
  margin-top: ${size.xl}px;
`;

const DesktopRightRailContainer = styled.div`
  margin-left: 128px;
  @media (max-width: ${breakpoints.max}px) {
    margin-left: 12px;
  }
  @media (max-width: ${breakpoints.xl}px) {
    margin-left: 6px;
  }
`;

const dealerHasPromoOffer = (displayedDealer) => {
  if (displayedDealer?.serviceCentersOffers?.length) return true; // Should we be removing all the logic relating to this?
  if (displayedDealer?.serviceCentersPromotions?.length) return true;
  return false;
};

const dealerHasServiceCenterHours= (displayedDealer) => {
  const serviceCenterHours =  displayedDealer?.serviceCentersDepartments?.filter((s) => s.departmentName === 'Service');
  if (serviceCenterHours?.length) return true;
  return false;
};

const ServiceTabContainerPageBase = ({ svocId, apiKey, client, shadowRoot, source }) => {
  const [dealerInfo, setDealerInfo] = useState();
  const [displayedDealer, setDisplayedDealer] = useState();
  const [payingDealer, setPayingDealer] = useState(false);
  const [hasMaintenanceAtDoor, setHasMaintenanceAtDoor] = useState(false);
  const [selectedMake, setSelectedMake] = useState();
  const [displayedMakes, setDisplayedMakes] = useState([]);
  const [earliestAppointmentDate, setEarliestAppointmentDate] = useState();
  const [hasOffers, setHasOffers] = useState(false);
  const [commonData, setCommonData] = useState();
  const [hasServiceCenterHours, setHasServiceCenterHours] = useState(false);

  const { width } = useAdaptiveRender();
  const isMobileViewport = width < breakpoints.lg + 1;

  const ddpDataLayer = getWinDataLayer();
  const ddpPixallDataLayer = getWinPixallDataLayer();
  // TODO: Uncomment when cors issue resolved
  useEffect(() => {
    const fetchData = async () => {
      const commonData = getDataLayerCommonData();
      const data = await fetchDealer(svocId, apiKey);
      setCommonData(commonData);
      setDealerInfo(data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    // Add logic here to get displayedDealer, payingDealer, and hasMaintenanceAtDoor
    if (dealerInfo?.items) {
      const dealerAndProperties = getDealerAndProperties(dealerInfo, selectedMake);
      if (dealerInfo.svocId !== dealerAndProperties?.displayedDealer?.svocId) {
        dealerInfo.svocId = dealerAndProperties?.displayedDealer?.svocId;
        setDealerInfo(dealerInfo);
      }
      setDisplayedDealer(dealerAndProperties.displayedDealer);
      setPayingDealer(dealerAndProperties.isPaying);
      setHasMaintenanceAtDoor(dealerAndProperties.hasMaintenanceAtDoor);
      setDisplayedMakes(dealerAndProperties.makes);
    }
  }, [dealerInfo, selectedMake]);

  useEffect(() => {
    setHasOffers(() => dealerHasPromoOffer(displayedDealer));
    setHasServiceCenterHours(() => dealerHasServiceCenterHours(displayedDealer));
  }, [displayedDealer]);

  const renderDesktopView = () => {
    return (
      <DesktopRightRailContainer>
        <FlexBox alignItems="end" flowColumn={true}>
          <ServiceCenterHoursOfOperation
            displayedDealer={displayedDealer}
          />
          {hasOffers && (
            <DealerOffersContainer>
              <DealerOffers
                dealerInfo={dealerInfo}
                shadowRoot={shadowRoot}
                ddpDataLayer={ddpDataLayer}
                ddpPixallDataLayer={ddpPixallDataLayer}
                displayedDealer={displayedDealer}
              />
            </DealerOffersContainer>
          )}
          <PhoneCallButtonContainer hasOffers={hasOffers}>
            <PhoneCallButton
              phoneNumber={displayedDealer.phone}
              ddpDataLayer={ddpDataLayer}
              ddpPixallDataLayer={ddpPixallDataLayer}
              displayedDealer={displayedDealer}
            />
          </PhoneCallButtonContainer>
          <ContactServiceButtonContainer>
            <ContactServiceButton
              dealerInfo={displayedDealer}
              buttonText="Contact Service Department"
              ddpDataLayer={ddpDataLayer}
              ddpPixallDataLayer={ddpPixallDataLayer}
              displayedDealer={displayedDealer}
            />
          </ContactServiceButtonContainer>
          {displayedDealer?.serviceSchedulingUrl && (
            <DealerWebsiteLink
              serviceSchedulingUrl={displayedDealer.serviceSchedulingUrl}
              ddpDataLayer={ddpDataLayer}
              ddpPixallDataLayer={ddpPixallDataLayer}
              displayedDealer={displayedDealer}
            />
          )}
        </FlexBox>
      </DesktopRightRailContainer>
    );
  };

  const renderMobileView = () => {
    return (
      <>
        <FlexBox justifyContent="space-between" flexWrap="wrap" verticalSpacing="sm">
          <ServiceCenterHoursOfOperation displayedDealer={displayedDealer} isMobileViewport={isMobileViewport} />
          {hasOffers && (
            <DealerOffersContainer>
              <DealerOffers
                dealerInfo={dealerInfo}
                shadowRoot={shadowRoot}
                ddpDataLayer={ddpDataLayer}
                ddpPixallDataLayer={ddpPixallDataLayer}
                displayedDealer={displayedDealer}
              />
            </DealerOffersContainer>
          )}
          <PhoneCallButtonContainer>
            <PhoneCallButton
              phoneNumber={displayedDealer.phone}
              ddpDataLayer={ddpDataLayer}
              ddpPixallDataLayer={ddpPixallDataLayer}
              displayedDealer={displayedDealer}
            />
          </PhoneCallButtonContainer>
        </FlexBox>
        <ContactServiceButtonContainer>
          <ContactServiceButton
            dealerInfo={displayedDealer}
            buttonText="Contact Service Department"
            ddpDataLayer={ddpDataLayer}
            ddpPixallDataLayer={ddpPixallDataLayer}
            displayedDealer={displayedDealer}
          />
        </ContactServiceButtonContainer>
        {displayedDealer?.serviceSchedulingUrl && (
          <DealerWebsiteLinkContainer>
            <DealerWebsiteLink
              serviceSchedulingUrl={displayedDealer.serviceSchedulingUrl}
              ddpDataLayer={ddpDataLayer}
              ddpPixallDataLayer={ddpPixallDataLayer}
              displayedDealer={displayedDealer}
            />
          </DealerWebsiteLinkContainer>
        )}
      </>
    );
  };

  return (
    <React.Fragment>
      {dealerInfo && displayedDealer ? (
        <>
          {payingDealer && (
            <>
              {!hasServiceCenterHours && (
                <EarliestAppointmentContainer >
                  <EarliestAppointment earliestAppointmentDate={earliestAppointmentDate} />
                </EarliestAppointmentContainer>
              )}
              <FlexBox justifyContent="space-between" flexWrap="nowrap" verticalSpacing="sm" horizontalSpacing="md">
                <ScheduledServiceContainer>
                 {hasServiceCenterHours && (
                 <EarliestAppointmentContainer >
                    <EarliestAppointment earliestAppointmentDate={earliestAppointmentDate} />
                  </EarliestAppointmentContainer>
                 )}
                  <ScheduleService
                    logger={window.logger}
                    dealerInfo={dealerInfo}
                    displayedDealer={displayedDealer}
                    setSelectedMake={setSelectedMake}
                    zipCode={null}
                    client={client}
                    displayedMakes={displayedMakes}
                    setEarliestAppointmentDate={setEarliestAppointmentDate}
                    commonData={commonData}
                    source={source}
                  />
                  <ServiceCenterFeatures
                    displayedDealer={displayedDealer}
                  />
                  <AboutServiceCenter
                    displayedDealer={displayedDealer}
                  />
                  <ServiceCenterServicesAndAmenities
                    displayedDealer={displayedDealer}
                  />
                  <ServiceCenterLanguages
                    displayedDealer={displayedDealer}
                  />
                </ScheduledServiceContainer>
                {!isMobileViewport && renderDesktopView()}
              </FlexBox>
            </>
          )}
          {(!payingDealer || isMobileViewport) && renderMobileView()}
          {hasMaintenanceAtDoor && (
            <DealerHomeServicesContainer>
              <DealerHomeServices />
            </DealerHomeServicesContainer>
          )}
          <PromoModuleContainer>
            <PromoModule
              make={selectedMake}
              promoUnits={['recallPromoUnit', 'repairPromoUnit', 'servicePromoUnit']}
              ddpDataLayer={ddpDataLayer}
            />
          </PromoModuleContainer>
        </>
      ) : (
        dealerInfo === null && <ErrorDealerInfoUnavailable />
      )}
    </React.Fragment>
  );
};

export default ServiceTabContainerPageBase;
