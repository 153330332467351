const getAnalyticsKeyword = (make, type, isTablet, isSmartPhone) => {
  const analyticsMake = make.substring(0, 5).toLowerCase();
  const analyticsDealerType = type.charAt(0).toLowerCase();
  const analyticsDeviceType = isSmartPhone ? 'm' : isTablet ? 't' : 'd';


  const analyticsDataLayer = typeof window !== 'undefined' && window.getDataLayer && window.getDataLayer();
  const S_PROP2 = analyticsDataLayer?.analytics?.detailpagename || '';
  const S_PROP31 = analyticsDataLayer?.paidsearch?.paidsearchid || '';

  const analyticsField = `${analyticsMake}^${analyticsDealerType}^${S_PROP31}^${S_PROP2}^${analyticsDeviceType}`;
  return encodeURI(analyticsField);
};

export default getAnalyticsKeyword;
