import React, { useState, useEffect } from 'react';
import { COMPONENT_STATE } from '../../constants/componentState';
import ServiceCalendar from './ServiceCalendar';
import CrmServiceCalendar from './Crm/CrmServiceCalendar';
import ErrorDisplay from './ErrorDisplay';
import { getCrmDaysOutOffset } from '../../utils/dateTimeUtils';
import styled from '@emotion/styled';

import { FlexBox } from '@argo/principles';
import { Button } from '@argo/ui-elements';

const StyledButton = styled(Button)`
  width: 380px;
  @media (max-width: 440px) {
    width: 100%;
  }
`;

const DateTimeBase = ({
  handleDateTimeSelected,
  componentState,
  dealerInfo,
  shortProvider,
  shortSubProvider,
  vehicleInfo,
  selectedService,
  calendarSelectedDate,
  setCalendarSelectedDate,
  calendarSelectedTime,
  setCalendarSelectedTime,
  availableAppointmentData,
  getAvailableAppointments,
  setEarliestAppointmentDate,
  appointmentInfo,
  setVehicleHasChanged,
  earliestDateXTime
}) => {
  useEffect(() => {
    if (dealerInfo?.provider.toLowerCase() === '') {
      setErrorState(true);
    }
  }, [dealerInfo]);

  // this useEffect enables next button if the dates are the same
  // when user selects a new date after completing, they have to select time to enable
  useEffect(() => {
    if (
      new Date(calendarSelectedDate).toLocaleDateString() === new Date(calendarSelectedTime).toLocaleDateString() &&
      calendarSelectedDate &&
      calendarSelectedTime
    ) {
      setIsSameDateInfo(true);
    } else {
      setIsSameDateInfo(false);
    }
  }, [calendarSelectedDate, calendarSelectedTime]);

  const [providerInfo, setProviderInfo] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const [errorState, setErrorState] = useState(false);
  const [isSameDateInfo, setIsSameDateInfo] = useState(false);

  const handleSubmitNext = () => {
    const selectedDateAndTime = {
      startDate: new Date(calendarSelectedDate).toLocaleDateString(),
      startTime: calendarSelectedTime,
      providerInfo: providerInfo,
      statusMessage: statusMessage
    };
    handleDateTimeSelected(selectedDateAndTime);
  };

  const setPostVariables = (providerInfo, statusMessage) => {
    setProviderInfo(providerInfo);
    setStatusMessage(statusMessage);
  };

  const dealerSvocId = dealerInfo.svocId.toString() || '';
  const dealerWebCode = dealerInfo.webCode || '';

  const CrmCalendar = () => {
    return (
      <CrmServiceCalendar
        data-lean-auto="service-calendar"
        isEntryView
        shortProvider={shortProvider}
        shortSubProvider={shortSubProvider}
        calendarSelectedDate={calendarSelectedDate}
        setCalendarSelectedDate={setCalendarSelectedDate}
        calendarSelectedTime={calendarSelectedTime}
        setCalendarSelectedTime={setCalendarSelectedTime}
        setEarliestAppointmentDate={setEarliestAppointmentDate}
        appointmentInfo={appointmentInfo}
        daysOutOffset={getCrmDaysOutOffset(dealerInfo)}
      />
    );
  };
  return (
    componentState.state === COMPONENT_STATE.active && (
      <FlexBox verticalSpacing="sm" default={['0 0 100%', '0 0 100%']} flexWrap="wrap">
        <div className='applitoolsLayoutOnly'>
          {
            {
              xtime: (
                <ServiceCalendar
                  data-lean-auto="service-calendar"
                  provider={dealerInfo.provider}
                  webCode={dealerWebCode}
                  svocId={dealerSvocId}
                  setPostVariables={setPostVariables}
                  isEntryView={true}
                  shortProvider={shortProvider}
                  shortSubProvider={shortSubProvider}
                  calendarSelectedDate={calendarSelectedDate}
                  setCalendarSelectedDate={setCalendarSelectedDate}
                  calendarSelectedTime={calendarSelectedTime}
                  setCalendarSelectedTime={setCalendarSelectedTime}
                  availableAppointmentData={availableAppointmentData}
                  getAvailableAppointments={getAvailableAppointments}
                  vehicleInfo={vehicleInfo}
                  selectedService={selectedService}
                  setEarliestAppointmentDate={setEarliestAppointmentDate}
                  setErrorState={setErrorState}
                  dealerInfo={dealerInfo}
                  appointmentInfo={appointmentInfo}
                  setVehicleHasChanged={setVehicleHasChanged}
                  earliestDateXTime={earliestDateXTime}
                />
              ),
              crm: CrmCalendar(),
              emailonly: CrmCalendar(),
              '': (
                <React.Fragment>
                  <div>
                    <br />
                    <ErrorDisplay
                      dataSection="cal"
                      shortProvider={shortProvider}
                      shortSubProvider={shortSubProvider}
                      dealerInfo={dealerInfo}
                    />
                  </div>
                </React.Fragment>
              )
            }[dealerInfo.provider.toLowerCase()]
          }
        </div>
        <FlexBox
          horizontalSpacing="sm"
          verticalSpacing="md"
          justifyContent="flex-start"
          flexWrap="nowrap"
          style={{ marginBottom: '0', marginTop: '0' }}
        >
          {!errorState && (
            <StyledButton
              text="Next"
              buttonType="secondary"
              disabled={isSameDateInfo === false}
              onClick={handleSubmitNext}
              target="_self"
              enableCapitalize
            />
          )}
        </FlexBox>
      </FlexBox>
    )
  );
};

export default DateTimeBase;
