import React, { useRef } from 'react';
import { COMPONENT_STATE } from '../constants/componentState';
import ContactInformationBase from './ContactInformationBase';
import { FlexBox, breakpoints } from '@argo/principles';
import { useAdaptiveRender } from '@argo/utils';
import { Box } from '@argo/ui-elements';
import { ProfileIcon } from '@argo/ui-elements';
import useDevice from '@kbbsdk/global-sdk/common/hooks/useDevice';
import styled from '@emotion/styled';
import SectionTitle from '../shared/SectionTitle';
import scheduleAppointmentQuery from '../tags/queries/scheduleAppointment';
import getAnalyticsKeyword from './Utils/getAnalyticsKeyword';
import ddpDataAnalytics  from '../utils/ddpDataAnalytics';
import ddpPixAllEvents from '@kbbhydra/service-tab-container/src/utils/pixAll/ddpPixAllEvents'

const StyledBox = styled(Box)`
  min-height: 100%;
`;

const ContactInformation = ({
  componentState,
  changeStateHandler,
  selectedHandler,
  vehicleInfo,
  selectedService,
  displayedDealer,
  calendarSelectedTime,
  availableAppointmentData,
  client,
  commonData,
  source,
  ddpDataLayer,
  ddpPixallDataLayer,
  setSuccessfulSchedule,
  setLoading
}) => {
  const vehicleData = vehicleInfo;
  const { YearId, MakeName, ModelName, ChromeStyleId, TrimName } = vehicleInfo;
  const { serviceName, comment, xTimeServiceName } = selectedService;
  const providerInfo = availableAppointmentData?.data?.availableAppointments?.providerInfo;
  const {
    name,
    address,
    city,
    state,
    zip,
    phone: dealerPhone,
    distance,
    dealerEmailAddress,
    primaryPhotoUrl,
    provider,
    subProvider,
    svocId,
    webCode,
    type
  } = displayedDealer;
  // TODO: Validate the values of getAnalyticsKeyword parameters

  const {
    issmartphone: isSmartPhone, // TBD - pass into DealerCard to control popover display
    istablet: isTablet
  } = useDevice() || {};

  const { width } = useAdaptiveRender();
  const isTabletViewport = isTablet ? isTablet : width < breakpoints.lg && width > breakpoints.md;
  const isMobieViewport = isSmartPhone ? isSmartPhone : width < breakpoints.md;
  const analyticsKeyword = getAnalyticsKeyword(MakeName, provider, isTabletViewport, isMobieViewport);

  const reportingLabel = {
    context: 'KBBUSAPI',
    value: analyticsKeyword
  };

  const applicationData = commonData?.common?.application;
  const businessUnit = applicationData?.businessUnit;

  const contactInformation = useRef({
    firstName: '',
    lastName: '',
    email: '',
    phone: ''
  });

  const getSourceFromDataLayer = (businessUnit) => {
    return `${businessUnit}-DDP`;
  };

  const getSourceFromHostApp = (source) => {
    return `${source}-DDP`;
  };

  const scheduleAppointment = async ({ firstName, lastName, email, phone }) => {
    ddpDataAnalytics.fireDdpClick(
      ddpDataLayer,
      ddpDataAnalytics.clicks.bookServiceAppointmentClickDdp(svocId, provider, type)
    );

    ddpPixAllEvents.fireDdpPixallClick(ddpPixallDataLayer, ddpPixAllEvents.clicks.bookServiceAppointmentClickDdp(vehicleInfo, svocId, provider));

    const scheduleAppointmentRequest = {
      vehicleInformation: {
        year: YearId?.toString(),
        make: MakeName,
        model: ModelName,
        trim: TrimName,
        vin: '',
        chromStyleId: ChromeStyleId
      },
      recallInformation: {
        recallKey: '',
        nhtsaIds: null
      },
      customerInformation: {
        firstName: firstName,
        lastName: lastName,
        emailAddress: email,
        phoneNumber: phone ? phone.replace(/-/g, '') : phone,
        comment: comment
      },
      dealerInformation: {
        name: name,
        address: address,
        city: city,
        state: state,
        zipCode: zip,
        currentDistance: distance,
        phoneNumber: dealerPhone,
        emailAddress: dealerEmailAddress,
        cardImage: primaryPhotoUrl,
        provider: provider?.toLowerCase(),
        subProvider: subProvider?.toLowerCase(),
        svocId: svocId.toString(),
        webCode: webCode
      },
      appointmentInformation: {
        providerInfo: providerInfo,
        kbbService: serviceName,
        serviceName: xTimeServiceName,
        appointmentDateTimeLocal: calendarSelectedTime,
        reportingLabel: reportingLabel,
        advisorId: '',
        priceRange: '',
        pixallId: '',
        source: source ? getSourceFromHostApp(source) : getSourceFromDataLayer(businessUnit)
      }
    };

    try {
      const scheduleAppointmentResult = await client.query({
        query: scheduleAppointmentQuery,
        variables: { scheduleAppointmentRequest },
        ssr: true
      });

      if (scheduleAppointmentResult?.data?.scheduleAppointment?.status === true) {
        setLoading(false);
      } else if (scheduleAppointmentResult?.data?.scheduleAppointment?.status === false) {
        setLoading(false);
        setSuccessfulSchedule(false);
      }
    } catch (ex) {
      setLoading(false);
      setSuccessfulSchedule(false);
      console.log(`Exception ${ex}`);
    }
  };

  const handleContactInfoSelected = (contactInformationData) => {
    contactInformation.current = contactInformationData;
    selectedHandler(contactInformation.current);
    changeStateHandler({ name: componentState.name, state: COMPONENT_STATE.completed });
    scheduleAppointment(contactInformationData);
  };

  const handleEditContactInformation = () => {
    changeStateHandler({ name: componentState.name, state: COMPONENT_STATE.active });
  };

  return (
    <StyledBox backgroundColor="white" response={false} shadow={false} width={'100%'}>
      <FlexBox flowColumn verticalSpacing="sm">
        <SectionTitle
          icon={ProfileIcon}
          iconTestId="profileIcon"
          componentState={componentState}
          completedText="Your Contact Information"
          defaultText="Your Contact Information"
          handleEdit={handleEditContactInformation}
        />
        <ContactInformationBase
          handleContactInfoSelected={(contactInformationData) => handleContactInfoSelected(contactInformationData)}
          componentState={componentState}
        />
      </FlexBox>
    </StyledBox>
  );
};

export default ContactInformation;
