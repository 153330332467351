import fetch from 'cross-fetch';
import { RetryLink } from 'apollo-link-retry';
import { ApolloClient, HttpLink, ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { BFF_URL, isDevelopment } from './constants/index'
import cache from './apolloCache';

// NOTE: we are only using universal-cookie here because this file is not included in SSR
// if there is code that is included in SSR, please use react-cookie instead:
// https://www.npmjs.com/package/react-cookie
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export default new ApolloClient({
  // Provide the URL to the API server.
  link: ApolloLink.from([
    new RetryLink({
      attempts: (count, operation, error) => {
        // Ignore PDADS calls since they seem to be very spammy
        return !!error && count < 5 && operation.operationName != 'pdads';
      }
    }),
    onError(({ graphQLErrors, networkError }) => {
      logger.logError({
        error: networkError,
        errorDetails: graphQLErrors ? JSON.stringify(graphQLErrors) : ''
      });

      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            // eslint-disable-line no-console
            `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${JSON.stringify(
              path
            )}`
          )
        );
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`); // eslint-disable-line no-console
      }
    }),
    // stateLink,
    new HttpLink({
      uri: `${BFF_URL}/api/ownership`,
      fetch: (uri, options) => {
        options.headers.mocks = cookies.get('mocks'); // eslint-disable-line

        return fetch(uri, options);
      }
    }),
  ]),
  // Using a cache for blazingly
  // fast subsequent queries.
  cache,
  connectToDevTools: isDevelopment
});
