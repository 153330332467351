import React from 'react';
import { FlexBox, typography, colors } from '@argo/principles';
import { COMPONENT_STATE } from '../../constants/componentState';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

const AnchorTagStyling = css`
  ${typography.defaults.XSMedium}
  position: relative;
  text-decoration: none;
  color: ${colors.action.darkBrightBlue};
`;

const StyledHeading = styled.h3`
  text-transform: none !important;
  ${typography.types.MSemi};
  color: ${colors.primary.black};
  margin-top: 0;
  margin-bottom: 0;
`;

const StyledPTag = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
`;

const StyledDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const SectionTitle = ({ icon, iconTestId, componentState, completedText, defaultText, handleEdit }) => {
  const IconComponent = icon;

  return (
    <FlexBox alignItems="center">
      <IconComponent data-testid={iconTestId} />
      {componentState.state === COMPONENT_STATE.completed ? (
        <StyledDiv>
          <StyledHeading className="applitoolsLayoutOnly" headingTag="h3">
            {completedText}
          </StyledHeading>
          <StyledPTag css={AnchorTagStyling} onClick={handleEdit}>
            Edit
          </StyledPTag>
        </StyledDiv>
      ) : (
        <StyledHeading headingTag="h3">{defaultText}</StyledHeading>
      )}
    </FlexBox>
  );
};

export default SectionTitle;
