import React from 'react';
import { typography, colors, size } from '@argo/principles';
import styled from '@emotion/styled';
import { CheckmarkIcon, Paragraph } from '@argo/ui-elements';


const StyledHeader = styled.h4`
  ${typography.types.SExtra};
  color: ${colors.primary.black};
  text-align: left;
  margin-bottom: ${size.xs}px;
`;

const StyledDiv = styled.div`
  ${typography.types.body};
  color: ${colors.primary.black};
  display: flex;
  gap: ${size.xs}px;
  align-items: flex-start;
  margin-bottom:${size.micro}px
`;

const StyledSpan = styled.span`
  display: inline-flex;
  WebkitBoxAlign: center;
  align-items: center;
  margin-top: ${size.micro}px;
`;

const StyledParagraph = styled(Paragraph)`
  display: flex;
  ${typography.types.body};
  color: ${colors.primary.black};
  align-items:center;
`;

const ServiceCenterFeatures = ({ displayedDealer }) => {
  const { serviceCentersFeatures } = displayedDealer;

  if (!serviceCentersFeatures){
    return null;
  }
  return (
    <div data-cy="ServiceDepartmentFeatures">
      <StyledHeader>Service Department Features</StyledHeader>       
      {serviceCentersFeatures.map((feature, index) => (
        <StyledDiv key={index}>
          <StyledSpan><CheckmarkIcon color="black" size="16" /></StyledSpan>
          <StyledParagraph>{feature}</StyledParagraph>
        </StyledDiv>
        ))
      }
    </div>
  )
}; 

export default ServiceCenterFeatures;
