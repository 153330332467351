import cookies from 'js-cookie';

//Used for DDP-ServiceTab Web ATC app, do not modify this function without checking with Aerosmith
export const getWinDataLayer = () => {
  let dataLayer = (typeof window !== 'undefined' && window.dataLayer ) || [];
  return dataLayer;
};

const processQueue = (currentWindow) => {
  currentWindow._pxam = currentWindow._pxam || [];
  if (currentWindow._pixall_queue) {
    currentWindow._pixall_queue.forEach((data) => {
      currentWindow._pxam.push(data);
    })
  }
  currentWindow._pixall_queue = [];

  currentWindow._pixall_queue.push = (data) => {
    currentWindow._pxam.push(data);
  }
};

const initialize = (currentWindow) => {
  if (typeof window !== 'undefined') {
    // Pixal does not retroactively fire the queue, we need to handle that ourselves
    const start = Date.now();
    const waitForPixall = () => {
      if(Date.now() - start <= 10000) {
        if (window.Pixall) {
          // Take the queue and push them all into _pxam
          processQueue(currentWindow);

          if (!cookies.get('pxa_realid') && cookies.get('pxa_id')) {
            cookies.set('pxa_realid', cookies.get('pxa_id'), { expires: 365 });
          }
        }
        else {
          setTimeout(waitForPixall, 500);
        }
      }
    }
    waitForPixall();
  }
};

export const getWinPixallDataLayer = () => {
  if(!window.Pixall) {
    initialize(window);
  };

  let dataLayer = window._pxam || [];
  return dataLayer;
};