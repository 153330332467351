
import { friendlyUrl } from '@kbbhydra/utils';
import { yearsQuery, makesQuery, modelsQuery, trims2Query } from '../tags/queries';


export const GetPrepopulatedVehicleDataAsync = async (year, makeName, modelName, trimName, client, vehicleId) => {
  try {

    const yearsResult = await client.query({
      query: yearsQuery,
      variables: {}
    });
    const yearsList = yearsResult.data.list;
    const filteredYears = yearsResult.data.list.filter((y) => y.text == year);
    const yearId = filteredYears.length > 0 && filteredYears[0].yearId;

    let makesList = null;
    let makeId;
    let realMakeName;
    if (yearId) {
      const makesResult = await client.query({
        query: makesQuery,
        variables: {
          yearId
        }
      });

      makesList = makesResult.data.list;
      if (makeName) {
        const filteredMakes = makesResult.data.list.filter(
          (m) => friendlyUrl(m.text.toLowerCase()) === friendlyUrl(makeName.toLowerCase())
        );

        if (filteredMakes && filteredMakes.length > 0) {
          makeId = filteredMakes[0].value;
          realMakeName = filteredMakes[0].text;
        }
      }
    }

    let modelsList = null;
    let modelId;
    let realModelName;
    if (yearId && makeId) {
      const modelsResult = await client.query({
        query: modelsQuery,
        variables: {
          yearId,
          makeId
        }
      });
      modelsList = modelsResult.data.list;
      if (modelName) {
        const filteredModels = modelsResult.data.list.filter(
          (m) => friendlyUrl(m.text.toLowerCase()) === friendlyUrl(modelName.toLowerCase())
        );

        if (filteredModels && filteredModels.length > 0) {
          modelId = filteredModels[0].value;
          realModelName = filteredModels[0].text;
        }
      }
    }

    let trimsList = null;
    let trimId;
    let realTrimName;
    if (yearId && makeId && modelId) {
      const trimsResult = await client.query({
        query: trims2Query,
        variables: {
          yearId,
          makeId,
          modelId
        }
      });
      trimsList = trimsResult.data.list;

      const filteredTrims =
        trimName && trimsResult.data.list.filter((m) => m.text.toLowerCase() === trimName.toLowerCase());

      const filteredTrimsByVehicleId = vehicleId && trimsResult.data.list.filter((m) => m.value == vehicleId);

      if (filteredTrims && filteredTrims.length > 0) {
        trimId = filteredTrims[0].value;
        realTrimName = filteredTrims[0].text;
      } else if (filteredTrimsByVehicleId && filteredTrimsByVehicleId.length > 0) {
        trimId = filteredTrimsByVehicleId[0].value;
        realTrimName = filteredTrimsByVehicleId[0].text;
      } else {
        trimId = trimsResult.data.list[0].value;
        realTrimName = trimsResult.data.list[0].text;
      }
    }

    const vehicleInfo = {
      YearId: year,
      MakeId: makeId,
      MakeName: realMakeName,
      ModelId: modelId,
      ModelName: realModelName,
      TrimId: trimId,
      TrimName: realTrimName,
      VehicleId: trimId,
      YearsList: yearsList,
      MakesList: makesList,
      ModelsList: modelsList,
      TrimsList: trimsList
    };
    return vehicleInfo;
  } catch (e) {
    console.log(`Exception ${e}`);
  }
};
