
export const getDataLayerCommonData = () => {
  const analyticsDataLayer = typeof window !== 'undefined' && window.dataLayer;
  let commonData, applicationData;
  console.log('analyticsDataLayer:', analyticsDataLayer);
  if (analyticsDataLayer) {
    commonData = analyticsDataLayer.filter((a) => a.common)[0];
    console.log('commonData:', commonData);
    console.log('applicationData:', applicationData);
  }
  return commonData;
}