import React from 'react';
import { Alert } from '@argo/ui-elements';
import styled from '@emotion/styled';
import { FlexBox } from '@argo/principles';

const StyledAlert = styled.div`
  max-width: auto;
  margin: 8px 0px 8px 0px;
  padding-left: 8px;
`;

const headingText = 'Sorry. Info Unavailable';

const bodyText = 'The system timed out or had another technical issue. Please try again later.';

const ErrorDealerInfoUnavailable = () => {
  return (
    <StyledAlert data-cy="errorDealerInfoUnavailable">
      <Alert type="caution" actionType="static" enableCapitalize heading={headingText} text={bodyText} />
    </StyledAlert>
  );
};

export default ErrorDealerInfoUnavailable;
