import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
// import { Click } from 'constants/analytics';
import { promoUnitsData, promoUnitsColors } from '../constants/promoModuleData';
import { PromoModule, PromoUnit } from '@argo/base-patterns';
import { size } from '@argo/principles';
import ddpDataAnalytics from '../../utils/ddpDataAnalytics';

const StyledPromoModuleContainer = styled.div`
  padding-top: ${size.sm}px;
  padding-bottom: ${size.xl}px;
`;

const PromoModuleWrapper = ({
  promoUnits,
  make,
  forceCarousel = false,
  ddpDataLayer,
  promoUnitDataAnalyticsPrefix = 'content_mod'
}) => {
  const baseOrigin = 'https://www.kbb.com';
  const baseUrl = make ? `${baseOrigin}/${make}` : baseOrigin;

  // ga implementation used here
  const onDotIndicatorClick = (index) => {
    ddpDataAnalytics.fireDdpClick(ddpDataLayer, ddpDataAnalytics.clicks.promoUnitContentModDotIndicator(index + 1));
  };

  const onNavClickCallBack = (direction) => {
    if (direction && typeof direction === 'number') {
      switch (direction) {
        case -1:
          ddpDataAnalytics.fireDdpClick(ddpDataLayer, ddpDataAnalytics.clicks.promoUnitContentModListScroll('left'));
          break;
        case 1:
          ddpDataAnalytics.fireDdpClick(ddpDataLayer, ddpDataAnalytics.clicks.promoUnitContentModListScroll('right'));
          break;
        default:
          console.log('[invalid number]');
      }
    }
  };

  return (
    <StyledPromoModuleContainer data-cy="promoModule">
      <PromoModule
        adaptive
        carousel
        dot
        forceCarousel={forceCarousel}
        fullWidth
        md={{ type: 'default', wrap: true }}
        onNavClickLTag="promo_list_scroll_left_content_mod"
        onNavClickRTag="promo_list_scroll_right_content_mod"
        onDotIndicatorClick={onDotIndicatorClick}
        onNavClickCallBack={onNavClickCallBack}
      >
        {promoUnits.map((promoUnitName, index) => {
          const promoUnitData = promoUnitsData[promoUnitName];
          return (
            promoUnitData && (
              <PromoUnit
                backgroundPosition="0"
                backgroundColor={promoUnitsColors[index]}
                buttonText={promoUnitData.PromoClickText}
                css={css`
                  max-width: 100%;
                  width: 100%;
                `}
                contentPadding="small"
                enableCapitalize
                headerTag="p"
                headerText={promoUnitData.PromoHeading}
                imagePadding="0"
                imageSrc={promoUnitData.PromoImg}
                introText={promoUnitData.PromoText}
                href={`${baseUrl}${promoUnitData.PromoUrl}`}
                isCarouselChild={false}
                isCollapse
                lazyImage={false}
                loading={false}
                noBoxShadow={false}
                shortSponsored="false"
                target="_self"
                textColor="white"
                type="fullWidth"
                useBackgroundImage={false}
                safeSpaceWidth="128px"
                id={`promoUnit${index + 1}`}
                key={`promoUnit${index + 1}`}
                onClick={() => {
                  ddpDataAnalytics.fireDdpClick(ddpDataLayer, ddpDataAnalytics.clicks.promoUnitContentMod(index + 1));
                }}
              />
            )
          );
        })}
      </PromoModule>
    </StyledPromoModuleContainer>
  );
};

export default PromoModuleWrapper;
