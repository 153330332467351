import { useQuery } from '@apollo/client';
import modelQuery from '../tags/model';

const useModelQuery = ({ client, year, make, model, vehicleid, skip: doSkip }) => {
  const skip = doSkip || !(year && make && model);

  const { data, error } = useQuery(modelQuery, {
    variables: {
      year,
      make,
      model,
    },
    skip,
    ssr: true,
    client
  });

  let vehicle = data?.vehicle || {};

  const { trims } = vehicle;
  if (vehicleid && trims && trims.length > 0) {
    // If the passed trim is populated
    const matchedTrim = trims.find((t) => t.defaultVehicleId?.toString() === vehicleid.toString());
    if (matchedTrim) {
      vehicle = { ...vehicle, selectedVehicleId: parseInt(matchedTrim.defaultVehicleId, 10) };
    }
  }

  return {
    data: vehicle,
    errors: error,
    loading: !(data || error || skip)
  };
};

export default useModelQuery;
