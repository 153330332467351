import React, { useRef } from 'react';
import { COMPONENT_STATE } from '../constants/componentState';
import { FlexBox } from '@argo/principles';
import { Box } from '@argo/ui-elements';
import styled from '@emotion/styled';
import { ServiceRepairIcon } from '@argo/ui-elements';
import ServicesBase from './Services';
import SectionTitle from '../shared/SectionTitle';
import ddpDataAnalytics from '../utils/ddpDataAnalytics';

const StyledBox = styled(Box)`
  min-height: 100%;
`;

const SelectService = ({ componentState, changeStateHandler, selectedHandler, ddpDataLayer, vehicleInfo, displayedDealer }) => {
  const selectedService = useRef('');
  const { provider, subProvider, svocId, type, distance } = displayedDealer;

  const handleServiceSelected = (service) => {
    let serviceIndex = parseInt(service.index, 10) + 1;
    ddpDataAnalytics.fireDdpClick(ddpDataLayer, ddpDataAnalytics.clicks.selectVehicleServiceApptClickDdp(vehicleInfo));
    ddpDataAnalytics.fireDdpClick(ddpDataLayer, ddpDataAnalytics.clicks.serviceCategoryEcomClick('dealer-contact', type, provider, svocId, distance, serviceIndex));

    selectedService.current = service;
    selectedHandler(selectedService.current);
    changeStateHandler({name: componentState.name, state: COMPONENT_STATE.completed});
  };

  const handleEditService = () => {
    changeStateHandler({ name: componentState.name, state: COMPONENT_STATE.active});
  }

  return (
    <StyledBox backgroundColor="white" response={false} shadow={false} width={'100%'}>
      <FlexBox flowColumn verticalSpacing="sm">
        <SectionTitle
          icon={ServiceRepairIcon}
          iconTestId="serviceIcon"
          componentState={componentState}
          completedText={selectedService?.current?.serviceName}
          defaultText="Your Service"
          handleEdit={handleEditService}
        />
          <ServicesBase
            handleServiceSelected={handleServiceSelected}
            componentState={componentState}
          />
      </FlexBox>
    </StyledBox>
  )
}

export default SelectService;
