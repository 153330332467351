import { gql } from '@apollo/client';

export default gql`
  query trimList($yearId: String, $makeId: String, $modelId: String) {
    list: trims2(year: $yearId, makeId: $makeId, modelId: $modelId) {
      text: trimName
      value: defaultVehicleId
    }
  }
`;
