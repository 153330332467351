export const promoUnitsData = {
  servicePromoUnit: {
    dataAnalytics: 'buytool',
    dataAnalyticsEvents: 'event106',
    dataPartner: '',
    PromoHeading: 'Maintenance Schedules & Pricing',
    SponsoredPromoHeading: '',
    PromoText: 'See a fair price for the scheduled maintenance services your car needs.',
    PromoUrl: `/maintenance-schedules/`,
    PromoImg: `https://file.kbb.com/kbb/images/ownership/maintenanceschedulesandpricing-bluebg.png`,
    PromoClickText: 'See Service Schedule & Pricing'
  },
  recallPromoUnit: {
    dataAnalytics: 'buytool',
    dataAnalyticsEvents: 'event106',
    dataPartner: '',
    PromoHeading: 'Does Your Car Have a Safety Recall?',
    SponsoredPromoHeading: '',
    PromoText: 'Millions of cars on the road have some sort of safety recalls.',
    PromoUrl: `/recall/`,
    PromoImg: `https://file.kbb.com/kbb/images/ownership/havearecallillustration-bluebg.png`,
    PromoClickText: 'See if your car is affected'
  },
  repairPromoUnit: {
    dataAnalytics: 'buytool',
    dataAnalyticsEvents: 'event106',
    dataPartner: '',
    PromoHeading: 'Common Car Repairs & Pricing',
    SponsoredPromoHeading: '',
    PromoText: 'Our Fair Repair Range shows what you should expect to pay for repairs.',
    PromoUrl: `/auto-repair/`,
    PromoImg: `https://file.kbb.com/kbb/images/ownership/commonrepairsandpricing-bluebg.png`,
    PromoClickText: 'See Common Repair Pricing'
  },
  doorServicePromoUnit: {
    dataAnalytics: 'buytool',
    dataAnalyticsEvents: 'event106',
    dataPartner: '',
    PromoHeading: 'Maintenance from your Door',
    SponsoredPromoHeading: '',
    PromoText: 'These service centers will provide no contact pickup and delivery.',
    PromoImg: `https://file.kbb.com/kbb/images/ownership/haveacarbroughttoyouillustration-bluebg.png`,
    PromoUrl: `/auto-service-centers/?valet_service=true`,
    PromoClickText: 'Find Service Centers Near You'
  },
  serviceCentersPromoUnit: {
    dataAnalytics: 'content_mod_1',
    dataAnalyticsEvents: '',
    dataPartner: '',
    PromoHeading: 'Find a Repair Shop',
    SponsoredPromoHeading: '',
    PromoText: 'Kelley makes it easy to find an auto shop that works best for you.',
    PromoImg: 'https://file.kbb.com/kbb/images/ownership/Obd2Images/StickyPromo/OBD-RCM-FindARepairShop.png',
    PromoUrl: '/service-centers/',
    PromoClickText: 'Find Repair Shops Near Me'
  },
  commonProblemsPromoUnit: {
    dataAnalytics: 'content_mod_2',
    dataAnalyticsEvents: '',
    dataPartner: '',
    PromoHeading: 'See Common Repairs',
    SponsoredPromoHeading: '',
    PromoText: 'Kelley shows you the most common repairs and problems for your car.',
    PromoImg: 'https://file.kbb.com/kbb/images/ownership/Obd2Images/StickyPromo/OBD-RCM-CommonRepairs.png',
    PromoUrl: '/service-repair-guide/',
    PromoClickText: 'Show Common Repairs for my Car'
  },
  servicePricingPromoUnit: {
    dataAnalytics: 'content_mod_3',
    dataAnalyticsEvents: '',
    dataPartner: '',
    PromoHeading: 'Get Service Pricing',
    SponsoredPromoHeading: '',
    PromoText: 'See a fair price for the scheduled maintenance services your car needs.',
    PromoImg: 'https://file.kbb.com/kbb/images/ownership/commonrepairsandpricing-bluebg.png',
    PromoUrl: '/maintenance-schedules/',
    PromoClickText: 'See Service Schedule & Pricing'
  }
};

export const promoUnitsColors = ['riverblue', 'airforceblue', 'marineblue'];
