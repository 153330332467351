import React from 'react';
import { Alert } from '@argo/ui-elements';

const ErrorDisplay = (props) => {
  const dealerName = props?.dealerInfo ? props?.dealerInfo?.name?.trim() : '';
  const dealerPhone = props?.dealerInfo ? props?.dealerInfo?.phone : '';

  return (
    <Alert
      actionType="static"
      data-lean-auto="alert"
      data-error-code="xti retrieve error"
      enableCapitalize
      heading="Please Call for An Appointment"
      text={`We're sorry about this, but there was an issue retrieving the information.  Please call the service provider, and they'll help set up your appointment.`}
      type="caution"
      subText={dealerName}
      subTextPhone={dealerPhone}
    />
  );
};

export default ErrorDisplay;
